import React, { useEffect, useState } from "react";
import FinBase from "../FinBase";
import * as XLSX from "xlsx";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import axios from "axios";
import config from "../../../functions/config";

function ChequePayments() {
  const navigate = useNavigate();
  function exportToExcel() {
    const Table = document.getElementById("chequesTable");
    const ws = XLSX.utils.table_to_sheet(Table);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, "Cheques_Payments.xlsx");
  }

  function sortTable(columnIndex) {
    var table, rows, switching, i, x, y, shouldSwitch;
    table = document.getElementById("chequesTable");
    switching = true;

    while (switching) {
      switching = false;
      rows = table.rows;

      for (i = 1; i < rows.length - 1; i++) {
        shouldSwitch = false;
        x = rows[i]
          .getElementsByTagName("td")
          [columnIndex].textContent.toLowerCase();
        y = rows[i + 1]
          .getElementsByTagName("td")
          [columnIndex].textContent.toLowerCase();

        if (x > y) {
          shouldSwitch = true;
          break;
        }
      }
      if (shouldSwitch) {
        rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
        switching = true;
      }
    }
  }

  function filterTable(rowIndex, filterValues) {
    const table = document.getElementById("chequesTable");
    const rows = table.getElementsByTagName("tr");
    let totalAmount = 0;

    const filters = filterValues.toLowerCase().split(',');

    for (let i = 1; i < rows.length; i++) {
        const row = rows[i];
        const statusCell = row.getElementsByTagName("td")[rowIndex];
        const amountCell = row.getElementsByTagName("td")[5]; // Assuming the 6th column is the amount
        const typeCell = row.getElementsByTagName("td")[1]; // Assuming the 2nd column is the type

        if (statusCell && amountCell && typeCell) {
            const status = statusCell.textContent.toLowerCase();
            const amountText = amountCell.textContent;
            const amount = parseFloat(amountText) || 0;
            const type = typeCell.textContent.toUpperCase(); // Assuming types are uppercase in the data

            // Check if the status matches any of the filter values or if "all" is selected
            const match = filters.includes(status) || filterValues === "all";

            // Show or hide rows based on the match condition
            row.style.display = match ? "" : "none";

            // Accumulate total amount for visible rows based on type
            if (match) {
                switch(type) {
                    case 'INVOICE':
                    case 'RECURRING INVOICE':
                    case 'SALES ORDER':
                    case 'RETAINER INVOICE':
                    case 'DEBIT NOTE':
                    case 'EMPLOYEE LOAN':
                    case 'ADDITIONAL LOAN':
                    case 'EMI PAID':
                        totalAmount += amount;
                        break;
                    case 'CREDIT NOTE':
                    case 'BILL':
                    case 'RECURRING BILL':
                    case 'PURCHASE ORDER':
                    case 'LOAN ACCOUNT':
                    case 'EMPLOYEE ADDITIONAL LOAN':
                    case 'LOAN EMI PAID':
                    case 'EXPENSE':
                        totalAmount -= amount;
                        break;
                }
            }
        }
    }
    // Update the displayed total amount
    document.getElementById("bal").textContent = totalAmount.toFixed(2);
}
  

  function sortHsnAscending() {
    var table = document.getElementById("chequesTable");
    var rows = Array.from(table.rows).slice(1);

    rows.sort(function (a, b) {
      var hsnA = parseInt(a.cells[2].textContent);
      var hsnB = parseInt(b.cells[2].textContent);
      return hsnA - hsnB;
    });

    // Remove existing rows from the table
    for (var i = table.rows.length - 1; i > 0; i--) {
      table.deleteRow(i);
    }

    // Append the sorted rows back to the table
    rows.forEach(function (row) {
      table.tBodies[0].appendChild(row);
    });
  }


  function searchTable() {
    const rows = document.querySelectorAll("#chequesTable tbody tr");
    let totalAmount = 0;
    const searchValue = document
      .getElementById("search")
      .value.trim()
      .replace(/ +/g, " ")
      .toLowerCase();
    
    rows.forEach(function (row) {
        const textContent = row.textContent.replace(/\s+/g, " ").toLowerCase();
        const amountCell = row.getElementsByTagName("td")[5];
        const typeCell = row.getElementsByTagName("td")[1]; // Assuming the type is in the 2nd column

        if (textContent.includes(searchValue)) {
            row.style.display = "";
            if (amountCell && typeCell) {
                const amount = parseFloat(amountCell.textContent) || 0;
                const type = typeCell.textContent.toUpperCase(); // Convert type to uppercase for consistency
                
                // Adjust total amount based on type
                switch (type) {
                    case 'INVOICE':
                    case 'RECURRING INVOICE':
                    case 'SALES ORDER':
                    case 'RETAINER INVOICE':
                    case 'DEBIT NOTE':
                    case 'EMPLOYEE LOAN':
                    case 'ADDITIONAL LOAN':
                    case 'EMI PAID':
                        totalAmount += amount;
                        break;
                    case 'CREDIT NOTE':
                    case 'BILL':
                    case 'RECURRING BILL':
                    case 'PURCHASE ORDER':
                    case 'LOAN ACCOUNT':
                    case 'EMPLOYEE ADDITIONAL LOAN':
                    case 'LOAN EMI PAID':
                    case 'EXPENSE':
                        totalAmount -= amount;
                        break;
                }
            }
        } else {
            row.style.display = "none";
        }
    });
    document.getElementById("bal").textContent = totalAmount.toFixed(2);
}



  const ID = Cookies.get("Login_id");
  const [upiPayments, setUpiPayments] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);

  const fetchUpiPayments = () => {
    axios
      .get(`${config.base_url}/chequesPayments/${ID}/`)
      .then((res) => {
        console.log("SO RES=", res);
        if (res.data.status) {
          const reportData = res.data.reportData;
          setUpiPayments(reportData);
          setTotalAmount(res.data.reportData[0].totalAmount);
        }
      })
      .catch((err) => {
        console.log("ERR", err);
      });
  };

  useEffect(() => {
    fetchUpiPayments();
  }, []);

  function refreshAll() {
    setUpiPayments([]);
    fetchUpiPayments();
  }

  

 
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const parseDate = (dateString) => {
      const parts = dateString.split('-');
      return new Date(parts[0], parts[1] - 1, parts[2]); // year, month (0-based), day
  };

  const filterTableByDate = () => {
      if (startDate !== '' && endDate !== '') {
          const start = parseDate(startDate);
          const end = parseDate(endDate);
          const table = document.getElementById('chequesTable');
          const rows = table.getElementsByTagName('tr');
          let totalAmount = 0;

          for (let i = 1; i < rows.length; i++) {
              const row = rows[i];
              const rowData = row.getElementsByTagName('td')[0].textContent; // Assuming the first column contains the date
              const rowDate = parseDate(rowData);
              const amountCell = row.getElementsByTagName('td')[5]; // Assuming the 6th column contains the amount
              const amount = parseFloat(amountCell.textContent) || 0;
              const typeCell = row.getElementsByTagName('td')[1]; // Assuming the 2nd column contains the type
              const type = typeCell.textContent.toUpperCase().trim(); // Adjust to the case you use

              if (rowDate >= start && rowDate <= end) {
                  row.style.display = '';
                  switch (type) {
                      case 'INVOICE':
                      case 'RECURRING INVOICE':
                      case 'SALES ORDER':
                      case 'RETAINER INVOICE':
                      case 'DEBIT NOTE':
                      case 'EMPLOYEE LOAN':
                      case 'ADDITIONAL LOAN':
                      case 'EMI PAID':
                          totalAmount += amount;
                          break;
                      case 'CREDIT NOTE':
                      case 'BILL':
                      case 'RECURRING BILL':
                      case 'PURCHASE ORDER':
                      case 'LOAN ACCOUNT':
                      case 'EMPLOYEE ADDITIONAL LOAN':
                      case 'LOAN EMI PAID':
                      case 'EXPENSE':
                          totalAmount -= amount;
                          break;
                  }
              } else {
                  row.style.display = 'none';
              }
          }
          document.getElementById('bal').textContent = totalAmount.toFixed(2);
      } else {
          alert('Select valid Start and End dates');
      }
  };



  return (
    <>
      <FinBase />
      <div
        className="page-content"
        style={{ backgroundColor: "#2f516f", minHeight: "100vh" }}
      >
        <div className="card radius-15 h-20">
          <div className="row">
            <div className="col-md-12">
              <center>
                <h2 className="mt-3">CHEQUE PAYMENTS</h2>
              </center>
              <hr />
            </div>
          </div>
        </div>

        <div className="card radius-15">
          <div className="card-body">
            <div className="container-fluid">
            <div className="row align-items-center">
            <div className="row justify-content-start">
              <div className="col-md-3 d-flex align-items-center">
                <input
                  type="text"
                  id="search"
                  className="form-control"
                  placeholder="Search.."
                  autoComplete="off"
                  onKeyUp={searchTable}
                />
                <div className="dropdown ml-1">
                  <button
                    type="button"
                    className="btn btn-outline-secondary dropdown-toggle text-grey"
                    data-toggle="dropdown"
                    style={{ width: "fit-content", height: "fit-content" }}
                  >
                    <i className="fa fa-sort"></i> Sort by
                  </button>
                  <div className="dropdown-menu" style={{ backgroundColor: "black" }}>
                    <a
                      className="dropdown-item"
                      style={{ height: "40px", fontSize: "15px", color: "white" }}
                      onClick={() => sortTable(0)}
                    >
                      Date
                    </a>
                    <a
                      className="dropdown-item"
                      style={{ height: "40px", fontSize: "15px", color: "white" }}
                      onClick={() => sortTable(2)}
                    >
                      Name
                    </a>
                    <a
                      className="dropdown-item"
                      style={{ height: "40px", fontSize: "15px", color: "white" }}
                      onClick={() => sortTable(4)}
                    >
                      Check No.
                    </a>
                  </div>
                </div>
              </div>
              
              <div className="col-132 col-md-9 d-flex flex-wrap justify-content-center justify-content-md-end">
                <div className="dropdown ml-2 d-flex align-items-center">
                  <select
                    style={{ width: "fit-content", height: "fit-content"}}
                    className="form-control btn btn-outline-secondary dropdown-toggle text-grey"
                    onChange={(e) => filterTable(1, e.target.value)}
                  >
                    <option value="all" style={{ height: "40px", fontSize: "15px", textAlign: "left" }}>All</option>
                    <option value="invoice" style={{ height: "40px", fontSize: "15px", textAlign: "left" }}>Invoice</option>
                    <option value="recurring invoice" style={{ height: "40px", fontSize: "15px", textAlign: "left" }}>Recurring Invoice</option>
                    <option value="credit note" style={{ height: "40px", fontSize: "15px", textAlign: "left" }}>Credit Note</option>
                    <option value="sales order" style={{ height: "40px", fontSize: "15px", textAlign: "left" }}>Sales Order</option>
                    <option value="retainer invoice" style={{ height: "40px", fontSize: "15px", textAlign: "left" }}>Retainer Invoice</option>
                    <option value="bill" style={{ height: "40px", fontSize: "15px", textAlign: "left" }}>Bill</option>
                    <option value="recurring bill" style={{ height: "40px", fontSize: "15px", textAlign: "left" }}>Recurring Bill</option>
                    <option value="purchase order" style={{ height: "40px", fontSize: "15px", textAlign: "left" }}>Purchase Order</option>
                    <option value="debit note" style={{ height: "40px", fontSize: "15px", textAlign: "left" }}>Debit Note</option>
                    <option value="expense" style={{ height: "40px", fontSize: "15px", textAlign: "left" }}>Expense</option>
                    <option value="loan,additional loan.,loan emi paid,employee loan,employee additional loan,emi paid" style={{ height: "40px", fontSize: "15px", textAlign: "left" }}>Loan</option>
                    {/* <option value="loan" style={{ height: "40px", fontSize: "15px", textAlign: "left" }}>Loan</option>
                    <option value="employee salary" style={{ height: "40px", fontSize: "15px", textAlign: "left" }}>Salary</option> */}
                  </select>
                  <button
                    type="button"
                    className="ml-2 btn btn-outline-secondary text-grey "
                    id="exportBtn"
                    onClick={exportToExcel}
                    style={{ width: "100px", height: "fit-content" }}
                  >
                    <i className="fa fa-table"></i> Export
                  </button>
                </div>
                <Link to="/ChequeStatement" className="ml-1">
                  <button
                    type="button"
                    style={{ width: "130px", height: "fit-content" }}
                    className="ml-2 btn btn-outline-secondary text-grey"
                  >
                    <i className="fa fa-file mr-1"></i> Statement
                  </button>
                </Link>
              </div>
            </div>
            <div className="d-flex flex-column flex-md-row justify-content-between align-items-center mt-3" style={{ width: '100%' }}>
            {/* Date Filter Section */}
            <div className="col-12 col-md-6 d-flex align-items-center justify-content-start pl-0 mb-3 mb-md-0">
                <div className="form-inline w-100">
                  <input
                    type="date"
                    name="start_date"
                    id="startDate"
                    className="form-control mr-2"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                    required
                  />
                  <span className="mx-1">TO</span>
                  <input
                    type="date"
                    name="end_date"
                    id="endDate"
                    className="form-control mr-1"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                    required
                  />
                  <button
                    className="btn btn-outline-secondary ml-1 flex-shrink-0"
                    type="button"
                    onClick={filterTableByDate}
                    style={{
                      height: '38px',
                      width: 'auto', 
                      padding: '0.375rem 0.75rem', 
                    }}
                  >
                    Filter
                  </button>
                </div>
              </div>

              <div className="bal_section d-flex justify-content-center justify-content-md-end pt-3 pr-3 col-12 col-md-auto">
                  <span className="h5 font-weight-bold text-info">TOTAL AMOUNT: <span id="bal">{totalAmount}</span></span>
              </div>
              </div>
            </div>
            </div>
          </div>
          
          <div className="table-responsive">
            <table
              className="table table-responsive-md table-hover mt-4"
              id="chequesTable"
              style={{ textAlign: "center" }}
            >
              <thead>
                <tr>
                <th style={{ textAlign: "center" }}>DATE</th>
                <th>TYPE</th>
                <th>NAME</th>
                <th>REF. NO.</th>
                <th>CHEQUE NO.</th>
                <th>AMOUNT</th>
                </tr>
              </thead>
              <tbody>
              {upiPayments &&
            upiPayments.map((payment) => (
              <>
                {payment.invoice.map((inv) => (
                  <tr key={inv.id}>
                    <td style={{ textAlign: "center" }}>{inv.invoice_date}</td>
                    <td>INVOICE</td>
                    <td>{inv.Customer.first_name} {inv.Customer.last_name}</td>
                    <td>{inv.reference_no}</td>
                    <td>{inv.cheque_no}</td>
                    <td>{inv.paid_off}</td>
                  </tr>
                ))}
                {payment.recInvoice.map((recInv) => (
                  <tr key={recInv.id} >
                    <td style={{ textAlign: "center" }}>{recInv.start_date}</td>
                    <td>RECURRING INVOICE</td>
                    <td>{recInv.Customer.first_name} {recInv.Customer.last_name}</td>
                    <td>{recInv.reference_no}</td>
                    <td>{recInv.cheque_no}</td>
                    <td>{recInv.paid_off}</td>
                  </tr>
                ))}
                {payment.creditNote.map((crdNt) => (
                  <tr key={crdNt.id}>
                    <td style={{ textAlign: "center" }}>{crdNt.credit_note_date}</td>
                    <td>CREDIT NOTE</td>
                    <td>{crdNt.Customer.first_name} {crdNt.Customer.last_name}</td>
                    <td>{crdNt.reference_no}</td>
                    <td>{crdNt.cheque_no}</td>
                    <td>{crdNt.paid}</td>
                  </tr>
                ))}
                {payment.salesOrder.map((sordr) => (
                  <tr key={sordr.id}>
                    <td style={{ textAlign: "center" }}>{sordr.sales_order_date}</td>
                    <td>SALES ORDER</td>
                    <td>{sordr.Customer.first_name} {sordr.Customer.last_name}</td>
                    <td>{sordr.reference_no}</td>
                    <td>{sordr.cheque_no}</td>
                    <td>{sordr.paid_off}</td>
                  </tr>
                ))}
                {payment.retainerInvoice.map((rtInv) => (
                  <tr key={rtInv.id}>
                    <td style={{ textAlign: "center" }}>{rtInv.ret_invoice_date}</td>
                    <td>RETAINER INVOICE</td>
                    <td>{rtInv.Customer.first_name} {rtInv.Customer.last_name}</td>
                    <td>{rtInv.reference_no}</td>
                    <td>{rtInv.cheque_no}</td>
                    <td>{rtInv.paid_off}</td>
                  </tr>
                ))}
                 {payment.bill.map((bill) => (
                  <tr key={bill.id}>
                    <td style={{ textAlign: "center" }}>{bill.bill_date}</td>
                    <td>BILL</td>
                    <td>{bill.Vendor.First_name} {bill.Vendor.Last_name}</td>
                    <td>{bill.reference_no}</td>
                    <td>{bill.cheque_no}</td>
                    <td>{bill.paid_off}</td>
                  </tr>
                ))}
                {payment.recurringBill.map((rcrbl) => (
                  <tr key={rcrbl.id}>
                    <td style={{ textAlign: "center" }}>{rcrbl.date}</td>
                    <td>RECURRING BILL</td>
                    <td>{rcrbl.Vendor.First_name} {rcrbl.Vendor.Last_name}</td>
                    <td>{rcrbl.reference_no}</td>
                    <td>{rcrbl.cheque_no}</td>
                    <td>{rcrbl.paid}</td>
                  </tr>
                ))}
                {payment.purchaseOrder.map((pordr) => (
                  <tr key={pordr.id}>
                    <td style={{ textAlign: "center" }}>{pordr.purchase_order_date}</td>
                    <td>PURCHASE ORDER</td>
                    <td>{pordr.Vendor.First_name} {pordr.Vendor.Last_name}</td>
                    <td>{pordr.reference_no}</td>
                    <td>{pordr.cheque_no}</td>
                    <td>{pordr.paid_off}</td>
                  </tr>
                ))}
                {payment.debitNote.map((dbtnt) => (
                  <tr key={dbtnt.id}>
                    <td style={{ textAlign: "center" }}>{dbtnt.debit_note_date}</td>
                    <td>DEBIT NOTE</td>
                    <td>{dbtnt.Vendor.First_name} {dbtnt.Vendor.Last_name}</td>
                    <td>{dbtnt.reference_no}</td>
                    <td>{dbtnt.cheque_no}</td>
                    <td>{dbtnt.paid}</td>
                  </tr>
                ))}
                {payment.expense.map((exp) => (
                  <tr key={exp.id}>
                    <td style={{ textAlign: "center" }}>{exp.expense_date}</td>
                    <td>EXPENSE</td>
                    <td>{exp.Vendor.First_name} {exp.Vendor.Last_name}</td>
                    <td>{exp.reference_no}</td>
                    <td>{exp.cheque_no}</td>
                    <td>{exp.amount}</td>
                  </tr>
                ))}
                {payment.empLoan.map((empLoan) => (
                  <tr key={empLoan.id}>
                    <td style={{ textAlign: "center" }}>{empLoan.loan_date}</td>
                    <td>EMPLOYEE LOAN</td>
                    <td>{empLoan.employee.first_name} {empLoan.employee.last_name}</td>
                    <td></td>
                    <td>{empLoan.cheque_number}</td>
                    <td>{empLoan.loan_amount}</td>
                  </tr>
                ))}
                {payment.empAddLoan.map((empAddLoan) => (
                  <tr key={empAddLoan.id}>
                    <td style={{ textAlign: "center" }}>{empAddLoan.new_date}</td>
                    <td>EMPLOYEE ADDITIONAL LOAN</td>
                    <td>{empAddLoan.employee.first_name} {empAddLoan.employee.last_name}</td>
                    <td></td>
                    <td>{empAddLoan.cheque_number}</td>
                    <td>{empAddLoan.new_loan}</td>
                  </tr>
                ))}
                {payment.loanRepay.map((lnRpy) => (
                  <tr key={lnRpy.id}>
                    <td style={{ textAlign: "center" }}>{lnRpy.payment_date}</td>
                    <td>EMI PAID</td>
                    <td>{lnRpy.employee.first_name} {lnRpy.employee.last_name}</td>
                    <td></td>
                    <td>{lnRpy.cheque_number}</td>
                    <td>{lnRpy.principle_amount}</td>
                  </tr>
                ))}
                {payment.loanAccount.map((loanAcc) => (
                <tr key={loanAcc.id}>
                  <td style={{ textAlign: "center" }}>{loanAcc.loan_date}</td>
                  <td>LOAN</td>
                  <td>{loanAcc.loan.account_name}</td>
                  <td></td>
                  <td>{loanAcc.recieved_cheque}</td>
                  <td>{loanAcc.loan_amount}</td>
                </tr>
              ))}
              {payment.loanAdditional.map((lonAddAcc) => (
                <tr key={lonAddAcc.id}>
                  <td style={{ textAlign: "center" }}>{lonAddAcc.loan_date}</td>
                  <td>ADDITIONAL LOAN.</td>
                  <td>{lonAddAcc.loan.account_name}</td>
                  <td></td>
                  <td>{lonAddAcc.recieved_cheque}</td>
                  <td>{lonAddAcc.loan_amount}</td>
                </tr>
              ))}
              {payment.emiPaid.map((lonAccEmi) => (
                <tr key={lonAccEmi.id}>
                  <td style={{ textAlign: "center" }}>{lonAccEmi.loan_date}</td>
                  <td>LOAN EMI PAID</td>
                  <td>{lonAccEmi.loan.account_name}</td>
                  <td></td>
                  <td>{lonAccEmi.recieved_cheque}</td>
                  <td>{lonAccEmi.loan_amount}</td>
                </tr>
              ))}

            
                
                {/* Add similar mapping for other payment types */}
              </>
            ))}
                
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}

export default ChequePayments;
