import React, { useEffect, useState } from "react";
import FinBase from "../FinBase";
import { Link, useNavigate ,useParams } from "react-router-dom";
import Cookies from "js-cookie";
import axios from "axios";
import config from "../../../functions/config";
import Swal from "sweetalert2";
import Select from "react-select";

function EditManualJournal() {
    const ID = Cookies.get("Login_id");
    const navigate = useNavigate();
    const [accounts, setAccounts] = useState([]);
    const [allMobiles, setAllMobiles] = useState([]);
    const { journalId } = useParams();

    const fetchManualJournalData = () => {
        axios
            .get(`${config.base_url}/fetch_manual_journal_data/${ID}/`)
            .then((res) => {
                console.log("MJ Data==", res);
                if (res.data.status) {
                    let accnt = res.data.acc;
                    console.log(accnt);

                    let contact = res.data.allMobiles;
                    console.log(contact);

                    setAccounts([]);
                    const newOptions = accnt.map((acct) => ({
                        label: acct.account_name,
                        value: acct.id,
                    }));
                    setAccounts(newOptions);

                    const mobileOptions = contact.map((mobile) => ({
                        label: mobile,
                        value: mobile,
                    }));
                    setAllMobiles(mobileOptions);

                    console.log("New Reference Number:", res.data.refNo);
                    console.log("New Manual Journal Number:", res.data.mjNo);

                    setRefNo(res.data.refNo);
                    setNextManualJournalNo(res.data.mjNo);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    function fetchAccounts() {
        axios
            .get(`${config.base_url}/fetch_manual_journal_data/${ID}/`)
            .then((res) => {
                if (res.data.status) {
                    let accnt = res.data.acc;

                    setAccounts([]);
                    const newOptions = accnt.map((acct) => ({
                        label: acct.name,
                        value: acct.id,
                    }));
                    setAccounts(newOptions);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    useEffect(() => {
        fetchManualJournalData();
    }, []);






    const fetchManualJournalDetails = () => {
        axios
          .get(`${config.base_url}/fetch_manual_journal_details/${journalId}/`)
          .then((res) => {
            console.log("MJ DET=", res);
            if (res.data.status) {
              var journal = res.data.journals;
              var acnts = res.data.accounts;
    
            
              
    
              setRefNo(journal.Reference_Number);
              setManualJournalNo(journal.Journal_Number);
              setCurrency(journal.Currency);
              setDebitTotal(journal.Debit_Total);
              setCreditTotal(journal.Credit_Total);
              setDebitDifference(journal.Debit_Difference);
              setCreditDifference(journal.Credit_Difference);
              setFile(journal.Document);
              setNotes(journal.Description);
              
              setJournalAccounts([])
              const journalAccounts = acnts.map((i)=>{
               
                return {
                  id: 1,
                  account: i.accountId,
                  name :i.name,
                  description : i.description,
                  contact: i.contact,
                  debits: i.debit,
                  credits: i.credit



                  
                 
                 
                }
              })
    
              setJournalAccounts(journalAccounts);
              refreshIndexes(journalAccounts);
    
            }
          })
          .catch((err) => {
            console.log("ERROR=", err);
            if (!err.response.data.status) {
              Swal.fire({
                icon: "error",
                title: `${err.response.data.message}`,
              });
            }
          });
      };
    
      useEffect(() => {
        fetchManualJournalDetails();
      }, []);
    
      function refreshIndexes(accounts){
        const accnts = accounts.map((acnt, index) => ({
          ...acnt,
          id: index + 1,
        }));
    
        setJournalAccounts(accnts)
      }
    





    

    const customStyles = {
        control: (provided) => ({
            ...provided,
            backgroundColor: "rgb(255 255 255 / 14%)",
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: "white",
        }),
        menu: (provided) => ({
            ...provided,
            backgroundColor: "white",
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected
                ? "lightgray"
                : state.isFocused
                ? "lightgray"
                : "white",
            color: state.isSelected ? "black" : "black",
        }),
        input: (provided) => ({
            ...provided,
            color: "white",
        }),
        placeholder: (provided) => ({
            ...provided,
            color: "white",
        }),
    };

    var currentDate = new Date();
    var formattedDate = currentDate.toISOString().slice(0, 10);
    const [refNo, setRefNo] = useState("");
    const [nextManualJournalNo, setNextManualJournalNo] = useState("");

    const [manualJournalNo, setManualJournalNo] = useState("");

    const [date, setDate] = useState(formattedDate);
    const [currency, setCurrency] = useState('');
    const [debitTotal, setDebitTotal] = useState('0.00');
    const [creditTotal, setCreditTotal] = useState('0.00');
    const [debitDifference, setDebitDifference] = useState('0.00');
    const [creditDifference, setCreditDifference] = useState('0.00');
    const [status, setStatus] = useState("");
    const [file, setFile] = useState(null);
    const [notes, setNotes] = useState("");
   
   
    

    const [journalAccounts, setJournalAccounts] = useState([
        {
            id: 1,
            account: "",
            description: "",
            contact: "",
            debits: 0,
            credits: 0,
        },
    ]);

    function checkForNull(val) {
        return val !== "" ? val : null;
    }

    function handleManualJournalNoChange(val) {
        setManualJournalNo(val);
        checkManualJournalNo(val);
    }
    function checkManualJournalNo(val) {
        var mj_num = val;
        if (mj_num !== "") {
            var m = {
                Id: ID,
                MJNum: mj_num,
            };
            axios
                .get(`${config.base_url}/check_manual_journal_no/`, {
                    params: m,
                })
                .then((res) => {
                    console.log("MJ NUM Res=", res);
                    var errorElement = document.getElementById("MJNoErr");
                    if (errorElement) {
                        errorElement.innerText = res.data.status ? "" : res.data.message;
                    } else {
                        console.error("Element with ID 'MJNoErr' not found.");
                    }
                })
                .catch((err) => {
                    console.log("ERROR=", err);
                    var errorElement = document.getElementById("MJNoErr");
                    if (errorElement) {
                        errorElement.innerText = "An error occurred.";
                    } else {
                        console.error("Element with ID 'MJNoErr' not found.");
                    }
                });
        }
    }
    
    

    const addNewRow = () => {
        setJournalAccounts((prevAccounts) => [
            ...prevAccounts,
            {
                id: prevAccounts.length + 1,
                account: "",
                description: "",
                contact: "",
                debits: 0,
                credits: 0,
            },
        ]);
    };

    const removeRow = (id) => {
        setJournalAccounts((prevAccounts) =>
            prevAccounts.filter((accnt) => accnt.id !== id)
        );
    };

    const handleAccountChange = (selectedOption, rowId) => {
        setJournalAccounts((prevAccounts) =>
            prevAccounts.map((accnt) =>
                accnt.id === rowId
                    ? {
                          ...accnt,
                          account: selectedOption ? selectedOption.value : "",
                      }
                    : accnt
            )
        );
    };

    const handleDescriptionChange = (e, rowId) => {
        setJournalAccounts((prevAccounts) =>
            prevAccounts.map((accnt) =>
                accnt.id === rowId
                    ? { ...accnt, description: e.target.value }
                    : accnt
            )
        );
    };

    const handleContactChange = (selectedOption, rowId) => {
        setJournalAccounts((prevAccounts) =>
            prevAccounts.map((accnt) =>
                accnt.id === rowId
                    ? {
                          ...accnt,
                          contact: selectedOption ? selectedOption.value : "",
                      }
                    : accnt
            )
        );
    };

    // Update the debits for a row
    const handleDebitsChange = (e, id) => {
        const updatedAccounts = journalAccounts.map((row) => {
            if (row.id === id) {
                return { ...row, debits: parseFloat(e.target.value) };
            }
            return row;
        });
        setJournalAccounts(updatedAccounts);
    };

    const handleCreditsChange = (e, id) => {
        const updatedAccounts = journalAccounts.map((row) => {
            if (row.id === id) {
                return { ...row, credits: parseFloat(e.target.value) };
            }
            return row;
        });
        setJournalAccounts(updatedAccounts);
    };

    function handleOrderDateChange(date) {
        setDate(date);
    }

    const calculateDebitSubtotal = () => {
        return journalAccounts
            .reduce((total, row) => total + parseFloat(row.debits || 0), 0)
            .toFixed(2);
    };

    // Function to calculate credit subtotal
    const calculateCreditSubtotal = () => {
        return journalAccounts
            .reduce((total, row) => total + parseFloat(row.credits || 0), 0)
            .toFixed(2);
    };

    // Function to calculate debit total amount
    const calculateDebitTotalAmount = () => {
        return calculateDebitSubtotal();
    };

    // Function to calculate credit total amount
    const calculateCreditTotalAmount = () => {
        return calculateCreditSubtotal();
    };

    // Function to calculate debit difference
    const calculateDebitDifference = () => {
        const debitTotal = parseFloat(calculateDebitSubtotal());
        const creditTotal = parseFloat(calculateCreditSubtotal());
        const difference = (debitTotal - creditTotal).toFixed(2);
        return difference >= 0 ? difference : 0;
    };

    // Function to calculate credit difference
    const calculateCreditDifference = () => {
        const debitTotal = parseFloat(calculateDebitSubtotal());
        const creditTotal = parseFloat(calculateCreditSubtotal());
        const difference = (creditTotal - debitTotal).toFixed(2);
        return difference >= 0 ? difference : 0;
    };



    useEffect(() => {
        setDebitTotal(calculateDebitTotalAmount());
        setCreditTotal(calculateCreditTotalAmount());
        setDebitDifference(calculateDebitDifference());
        setCreditDifference(calculateCreditDifference());
    }, [journalAccounts]);

    const handleCurrencyChange = (e) => {
        setCurrency(e.target.value);
    };



    
  const handleSubmit = (e) => {
    e.preventDefault();




    const formData = new FormData();
    formData.append("Id", ID);
    formData.append("status", status);
    console.log("journalAccounts before stringifying:", manualJournalNo);
    
    formData.append("manualJournalNo", manualJournalNo);
    formData.append("status", status);
    formData.append("reference_no", refNo);
    formData.append("date", date);
    formData.append("currency", currency);
    formData.append("note", notes);
    formData.append("debitTotal", debitTotal);
    formData.append("creditTotal", creditTotal);
    formData.append("debitDifference", debitDifference);
    formData.append("creditDifference", creditDifference);
    console.log("journalAccounts before stringifying:", journalAccounts);
    formData.append("journalAccounts", JSON.stringify(journalAccounts));

   
    formData.append("file", file);
    
    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000
      });


    axios
    .put(`${config.base_url}/update_manual_journal/${journalId}/`, formData)
    .then((res) => {
      console.log("journal RES=", res);
      if (res.data.status) {
        Toast.fire({
          icon: "success",
          title: "Manual Journal Updated",
        });
        navigate(`/view_manual_journal/${journalId}/`);
      }
      if (!res.data.status && res.data.message != "") {
        Swal.fire({
          icon: "error",
          title: `${res.data.message}`,
        });
      }
    })
    .catch((err) => {
      console.log("ERROR=", err);
      if (!err.response.data.status) {
        Swal.fire({
          icon: "error",
          title: `${err.response.data.message}`,
        });
      }
    });
};


    return (
        <>
            <FinBase />
            <div
                className="page-content mt-0 pt-0"
                style={{ backgroundColor: "#2f516f", minHeight: "100vh" }}
            >
                <div className="d-flex justify-content-end mb-1">
                    <Link to={"/manualjournal"}>
                        <i
                            className="fa fa-times-circle text-white mx-4 p-1"
                            style={{
                                fontSize: "1.2rem",
                                marginRight: "0rem !important",
                            }}
                        ></i>
                    </Link>
                </div>
                <div className="card radius-15 h-20">
                    <div className="row">
                        <div className="col-md-12">
                            <center>
                                <h2 className="mt-3">MANUAL JOURNAL</h2>
                            </center>
                            <hr />
                        </div>
                    </div>
                </div>

                <div className="card radius-15">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12 col-lg-12 col-xl-12"></div>
                        </div>
                        <form  className="needs-validation px-1"
          encType="multipart/form-data"
          onSubmit={handleSubmit} validate>
                            <div className="row  w-100" id="inventorytrack">
                                <div className="col-md-4 mt-3">
                                    <label style={{ color: "white" }}>
                                        Journal No.
                                    </label>
                                    <span className="text-danger ml-3" id="MJNoErr"></span>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="manual_journal_no"
                                        id="manualJournalNumber"
                                        value={manualJournalNo}
                                        onChange={(e) =>
                                            handleManualJournalNoChange(
                                                e.target.value
                                            )
                                        }
                                        style={{ backgroundColor: "#43596c" }}
                                        placeholder={nextManualJournalNo}
                                        required
                                        autocomplete="off"
                                    />
                                </div>
                                <div className="col-md-4 mt-3">
                                    <label style={{ color: "white" }}>
                                        Reference Number
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="reference_number"
                                        value={refNo}
                                        style={{ backgroundColor: "#43596c" }}
                                        readOnly
                                    />
                                </div>
                                <div className="col-md-4 mt-3">
                                    <label style={{ color: "white" }}>
                                        Journal Date:
                                    </label>
                                    <input
                                        type="date"
                                        name="date"
                                        className="form-control"
                                        style={{
                                            backgroundColor: "#2a4964",
                                            color: "white",
                                        }}
                                        value={date}
                                        onChange={(e) =>
                                            handleOrderDateChange(
                                                e.target.value
                                            )
                                        }
                                    />
                                </div>
                            </div>

                            <div className="row w-100">
                                <div className="col-md-12 mx-0">
                                    <div className="row">
                                        <div className="col-md-4 mt-3">
                                            <label
                                                for="itemName"
                                                style={{ color: "white" }}
                                            >
                                                Currency
                                            </label>
                                            <select
                                                id="currencySelect"
                                                name="currency"
                                                className="form-control"
                                                style={{
                                                    backgroundColor: "#2a4964",
                                                    color: "white",
                                                }}
                                                autoComplete="off"
                                                required
                                                value={currency} // Set value to the state
                                                onChange={handleCurrencyChange} // Handle change
                                            
                                            >
                                                <option
                                                    value=""
                                                    disabled
                                                    selected
                                                >
                                                    Select a Currency
                                                </option>
                                                <option value="INR">
                                                    INR - Indian Rupee
                                                </option>
                                            </select>
                                        </div>
                                        <div className="col-md-4 mt-3">
                                            <label
                                                for="itemType"
                                                style={{ color: "white" }}
                                            >
                                                Notes
                                            </label>
                                            <textarea
                                                name="notes"
                                                rows="5"
                                                className="form-control"
                                                style={{
                                                    backgroundColor: "#2a4964",
                                                    color: "white",
                                                }}
                                                value={notes}
                                                onChange={(e) => setNotes(e.target.value)}  
                                            />
                                        </div>
                                    </div>

                                    <div className="row" id="valueDiv">
                                        <div className="col-sm-12 col-lg-12 col-md-12 ">
                                            <label
                                                for=""
                                                id="label_tab2"
                                                className="tab2"
                                            ></label>
                                            <br />
                                            <div className="table-responsive" style={{ width: "100%" }}>
                                                <table
                                                    className="table  text-black table-bordered tab2"
                                                    id="item_table_value"
                                                    style={{ width: "100%" }}
                                                >
                                                    <thead>
                                                        <tr className="text-center">
                                                            <th
                                                                className=""
                                                                style={{
                                                                    fontWeight:
                                                                        "bold",
                                                                }}
                                                            >
                                                                #
                                                            </th>
                                                            <th
                                                                className=""
                                                                style={{
                                                                    fontWeight:
                                                                        "bold",
                                                                }}
                                                            >
                                                                ACCOUNT
                                                            </th>
                                                            <th
                                                                className=""
                                                                style={{
                                                                    fontWeight:
                                                                        "bold",
                                                                }}
                                                            >
                                                                DESCRIPTION
                                                            </th>
                                                            <th
                                                                className=""
                                                                style={{
                                                                    fontWeight:
                                                                        "bold",
                                                                }}
                                                            >
                                                                CONTACT
                                                            </th>
                                                            <th
                                                                className=""
                                                                style={{
                                                                    fontWeight:
                                                                        "bold",
                                                                }}
                                                            >
                                                                DEBITS
                                                            </th>
                                                            <th
                                                                className=""
                                                                style={{
                                                                    fontWeight:
                                                                        "bold",
                                                                }}
                                                            >
                                                                CREDITS
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody id="accounts-table-body">
                                                        {journalAccounts.map(
                                                            (row, index) => (
                                                                <tr
                                                                    key={row.id}
                                                                >
                                                                    <td
                                                                        className="nnum"
                                                                        style={{
                                                                            textAlign:
                                                                                "center",
                                                                        }}
                                                                    >
                                                                        {row.id}
                                                                    </td>
                                                                    <td
                                                                        style={{
                                                                            width: "20%",
                                                                        }}
                                                                    >
                                                                        <div className="d-flex align-items-center">
                                                                            <Select
                                                                                options={
                                                                                    accounts
                                                                                }
                                                                                styles={
                                                                                    customStyles
                                                                                }
                                                                                name={`account-${row.id}`}
                                                                                className="w-100"
                                                                                required
                                                                                value={accounts.find(
                                                                                    (
                                                                                        option
                                                                                    ) =>
                                                                                        option.value ===
                                                                                        row.account
                                                                                )}
                                                                                onChange={(
                                                                                    option
                                                                                ) =>
                                                                                    handleAccountChange(
                                                                                        option,
                                                                                        row.id
                                                                                    )
                                                                                }
                                                                                isClearable
                                                                                isSearchable
                                                                            />
                                                                        </div>
                                                                    </td>
                                                                    <td className="text-right text-dark">
                                                                        <input
                                                                            className="form-control border-1 text-light text-right"
                                                                            id=""
                                                                            name={`desc-${row.id}`}
                                                                            value={
                                                                                row.description
                                                                            }
                                                                            placeholder="Enter Description"
                                                                            onChange={(
                                                                                e
                                                                            ) =>
                                                                                handleDescriptionChange(
                                                                                    e,
                                                                                    row.id
                                                                                )
                                                                            }
                                                                        />
                                                                    </td>
                                                                    <td
                                                                        style={{
                                                                            width: "20%",
                                                                        }}
                                                                    >
                                                                        <div className="d-flex align-items-center">
                                                                            <Select
                                                                                options={
                                                                                    allMobiles
                                                                                }
                                                                                styles={
                                                                                    customStyles
                                                                                }
                                                                                name={`contact-${row.id}`}
                                                                                className="w-100"
                                                                                required
                                                                                value={
                                                                                    allMobiles.find(
                                                                                        (
                                                                                            option
                                                                                        ) =>
                                                                                            option.value ===
                                                                                            row.contact
                                                                                    ) ||
                                                                                    null
                                                                                }
                                                                                onChange={(
                                                                                    option
                                                                                ) =>
                                                                                    handleContactChange(
                                                                                        option,
                                                                                        row.id
                                                                                    )
                                                                                }
                                                                                isClearable
                                                                                isSearchable
                                                                            />
                                                                        </div>
                                                                    </td>

                                                                    <td className="text-right">
                                                                        <input
                                                                            type="number"
                                                                            className="form-control border-1 text-light text-right"
                                                                            name={`debit-${row.id}`}
                                                                            placeholder="0"
                                                                            value={
                                                                                row.debits
                                                                            }
                                                                            onChange={(
                                                                                e
                                                                            ) =>
                                                                                handleDebitsChange(
                                                                                    e,
                                                                                    row.id
                                                                                )
                                                                            }
                                                                        />
                                                                    </td>
                                                                    <td className="text-right">
                                                                        <input
                                                                            type="number"
                                                                            className="form-control border-1 text-light text-right"
                                                                            name={`credit-${row.id}`}
                                                                            placeholder="0"
                                                                            value={
                                                                                row.credits
                                                                            }
                                                                            onChange={(
                                                                                e
                                                                            ) =>
                                                                                handleCreditsChange(
                                                                                    e,
                                                                                    row.id
                                                                                )
                                                                            }
                                                                        />
                                                                    </td>
                                                                    <td>
                                                                        <button
                                                                            type="button"
                                                                            id={`${row.id}`}
                                                                            style={{
                                                                                width: "fit-content",
                                                                                height: "fit-content",
                                                                            }}
                                                                            onClick={() =>
                                                                                removeRow(
                                                                                    row.id
                                                                                )
                                                                            }
                                                                            className="btn btn-danger remove_row px-2 py-1 mx-1 fa fa-close"
                                                                            title="Remove Row"
                                                                        ></button>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        )}
                                                    </tbody>
                                                </table>
                                                <td style={{ border: "none" }}>
                                                    <a
                                                        id="add-new-row"
                                                        onClick={addNewRow}
                                                        className="btn btn-secondary"
                                                        style={{
                                                            width: "fit-content",
                                                            height: "fit-content",
                                                        }}
                                                    >
                                                        +
                                                    </a>
                                                </td>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row ">
                                        <div class="col-md-3">
                                            <label style={{ color: "white" }}>
                                                Attach file
                                            </label>
                                            <input
                                                class="form-control"
                                                type="file"
                                                id="file"
                                                name="file"
                                               
                                                style={{ marginTop: "10px", width: "70%" }}
                      onChange={(e) => setFile(e.target.files[0])}
                                            />
                                        </div>
                                        <div className="col-sm-4 mt-2"> </div>

                                        <div
                                            className="col-md-5 table-responsive-md mt-3 "
                                            id="purchaseOrderItemsTableTotal"
                                            style={{
                                                backgroundColor:
                                                    "rgba(0,0,0,0.4)",
                                                border: "1px solid rgba(128, 128, 128, 0.6)",
                                                marginLeft: "-2vh",
                                            }}
                                        >
                                            <div className="p-3">
                                                <div className="row container-fluid p-2 m-0">
                                                    <div className="col-sm-3 mt-2">
                                                        <label className="text-center">
                                                            Sub Total
                                                        </label>
                                                    </div>
                                                    <div className="col-sm-1 mt-2">
                                                        :
                                                    </div>
                                                    <div className="col-sm-4 mt-2">
                                                        <input
                                                            type="number"
                                                            step="any"
                                                            name="debitsubtotal"
                                                            className="form-control"
                                                            value={calculateDebitSubtotal()}
                                                        />
                                                    </div>
                                                    <div className="col-sm-4 mt-2">
                                                        <input
                                                            type="number"
                                                            step="any"
                                                            name="creditsubtotal"
                                                            className="form-control"
                                                            value={calculateCreditSubtotal()}
                                                            readOnly
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row container-fluid p-2 m-0">
                                                    <div className="col-sm-3 mt-2">
                                                        <label className="text-center">
                                                            Total Amount
                                                        </label>
                                                    </div>
                                                    <div className="col-sm-1 mt-2">
                                                        :
                                                    </div>
                                                    <div className="col-sm-4 mt-2">
                                                        <input
                                                            type="number"
                                                            step="any"
                                                            className="form-control"
                                                            value={calculateDebitTotalAmount()}
                                                            readOnly
                                                        />
                                                    </div>
                                                    <div className="col-sm-4 mt-2">
                                                        <input
                                                            type="number"
                                                            step="any"
                                                            className="form-control"
                                                            value={calculateCreditTotalAmount()}
                                                            readOnly
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row container-fluid p-2 m-0">
                                                    <div className="col-sm-3 mt-2">
                                                        <label className="text-center">
                                                            Difference
                                                        </label>
                                                    </div>
                                                    <div className="col-sm-1 mt-2">
                                                        :
                                                    </div>
                                                    <div className="col-sm-4 mt-2">
                                                        <input
                                                            type="number"
                                                            step="any"
                                                            className="form-control"
                                                            value={calculateDebitDifference()}
                                                            readOnly
                                                        />
                                                    </div>
                                                    <div className="col-sm-4 mt-2">
                                                        <input
                                                            type="number"
                                                            step="any"
                                                            className="form-control"
                                                            value={calculateCreditDifference()}
                                                            readOnly
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-7 mt-3">
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    value=""
                                                    id="agreeTerms"
                                                    required
                                                    style={{
                                                        backgroundColor:
                                                            "#43596c",
                                                    }}
                                                />
                                                <label for="agreeTerms">
                                                    Agree to terms and
                                                    conditions
                                                </label>
                                                <div className="invalid-feedback">
                                                    You must agree before
                                                    submitting.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-5 mt-3 d-flex">
                                        <input
                      type="submit"
                      className="btn btn-outline-secondary w-50 text-light"
                      value="Save"
                    />
                                            <input
                      type="reset"
                      className="btn btn-outline-secondary w-50 ml-1 text-light"
                      value="Cancel"
                      onClick={()=>navigate(`/view_manual_journal/${journalId}/`)}
                    />
                                        </div>
                                    </div>
                                    <div className="notices mt-3">
                                        <div className="text-muted">
                                            NOTICE:
                                        </div>
                                        <div className="text-muted">
                                            Fin sYs Terms and Conditions Apply
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}

export default EditManualJournal;
