import React, { useEffect, useState } from "react";
import FinBase from "../FinBase";
import { Link, useNavigate, useParams } from "react-router-dom";
import Cookies from "js-cookie";
import html2pdf from "html2pdf.js";
import axios from "axios";
import * as XLSX from "xlsx";
import config from "../../../functions/config";
import Swal from "sweetalert2";
import "../../styles/gstr.css";


function GSTR9() {
  const ID = Cookies.get("Login_id");

  const [reportData, setReportData] = useState([]);
  const [invData, setinvData] = useState([]);
  const [otherDetails, setOtherDetails] = useState({});
  const [taxdetails, settaxdetails] = useState({});
  const [reportpurchaseData, setreportpurchaseData] = useState([]);


  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [status, setStatus] = useState("");
  const [report, setReport] = useState("");

  const fetchInvoiceReportDetails = () => {
    axios
      .get(`${config.base_url}/GSTR9/${ID}/`)
      .then((res) => {
        console.log("REPRT DATA=", res);
        if (res.data.status) {
          setReportData(res.data.reportData);
          setinvData(res.data.invData);
          setOtherDetails(res.data.otherDetails);
          settaxdetails(res.data.tax_details)
          setreportpurchaseData(res.data.reportpurchaseData)
          setStartDate("");
          setEndDate("");
          setStatus("");
          setReport("");
        }
      })
      .catch((err) => {
        console.log("ERROR=", err);
        if (!err.response.data.status) {
          Swal.fire({
            icon: "error",
            title: `${err.response.data.message}`,
          });
        }
      });
  };

  useEffect(() => {
    fetchInvoiceReportDetails();
  }, []);

  const currentUrl = window.location.href;
  const shareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
    currentUrl
  )}`;

  const navigate = useNavigate();

  const handleCustomize = (e) => {
    e.preventDefault();

    var det = {
      Id: ID,
      start_date: startDate,
      end_date: endDate,
      report: report,
      status: status,
    };

    axios
      .post(`${config.base_url}/gstr9Customized/`, det)
      .then((res) => {
        console.log("REPRT DATA=", res);
        if (res.data.status) {
          setReportData(res.data.reportData);
          // setCNotedata(res.data.cNotedata);
          setOtherDetails(res.data.otherDetails);
          setStartDate(res.data.startDate);
          setEndDate(res.data.endDate);
          setStatus(res.data.filterStatus);
          setReport(res.data.report);
          setreportpurchaseData(res.data.reportpurchaseData)


          var contentDiv = document.getElementById("contentDiv");
          if (contentDiv.style.display === "block") {
            toggleContent();
          }
        }
      })
      .catch((err) => {
        console.log("ERROR=", err);
        if (!err.response.data.status) {
          Swal.fire({
            icon: "error",
            title: `${err.response.data.message}`,
          });
        }
      });
  };

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    },
  });

  function printSection() {
    var divToPrint = document.getElementById("printReport");
    var printWindow = window.open("", "", "height=700,width=1000");

    printWindow.document.write("<html><head><title></title>");
    printWindow.document.write(`
        <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.6.2/dist/css/bootstrap.min.css" integrity="sha384-xOolHFLEh07PJGoPkLv1IbcEPTNtaed2xpHsD9ESMhqIYd0nLMwNLD69Npy4HI+N" crossorigin="anonymous">
        <link rel="preconnect" href="https://fonts.googleapis.com">
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
        <link href="https://fonts.googleapis.com/css2?family=Agbalumo&family=Black+Ops+One&family=Gluten:wght@100..900&family=Playball&display=swap" rel="stylesheet">
    `);
    printWindow.document.write("</head>");
    printWindow.document.write("<body>");
    printWindow.document.write(divToPrint.outerHTML);
    printWindow.document.write("</body>");
    printWindow.document.write("</html>");
    printWindow.document.close();
    printWindow.print();
    printWindow.addEventListener("afterprint", function () {
      printWindow.close();
    });
  }

  function reportPDF() {
    var st = startDate;
    var en = endDate;
    var date = "";
    if (st != "" && en != "") {
      date = `_${startDate}` + "_" + `${endDate}`;
    }
    var element = document.getElementById("printReport");
    var opt = {
      margin: [0.5, 0.3, 0.3, 0.5],
      filename: "Report_gstr9" + date,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "cm", format: "letter", orientation: "portrait" },
    };
    html2pdf().set(opt).from(element).save();
  }

  function ExportToExcel() {
    var st = startDate;
    var en = endDate;
    var date = "";
    if (st != "" && en != "") {
        date = `_${startDate}` + "_" + `${endDate}`;
    }
    const Table1 = document.getElementById("printReport");
    const ws1 = XLSX.utils.table_to_sheet(Table1);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws1, "gstr9");
    XLSX.writeFile(wb, "Report__gstr9" + date + ".xlsx");
}

  

  const [emailIds, setEmailIds] = useState("");
  const [emailMessage, setEmailMessage] = useState("");

  function handleShareEmail(e) {
    e.preventDefault();

    var st = startDate;
    var end = endDate;
    var stat = status;
    var rpt = report;

    if ((st != "" && end == "") || (st == "" && end != "")) {
      alert("Please select both date inputs or select none");
      return;
    } else {
      var emailsString = emailIds.trim();

      var emails = emailsString.split(",").map(function (email) {
        return email.trim();
      });

      var emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;

      var invalidEmails = [];
      if (emailsString === "") {
        alert("Enter valid email addresses.");
      } else {
        for (var i = 0; i < emails.length; i++) {
          var currentEmail = emails[i];

          if (currentEmail !== "" && !emailRegex.test(currentEmail)) {
            invalidEmails.push(currentEmail);
          }
        }

        if (invalidEmails.length > 0) {
          alert("Invalid emails. Please check!\n" + invalidEmails.join(", "));
        } else {
          // document.getElementById("share_to_email_form").submit();
          var em = {
            Id: ID,
            start: st,
            end: end,
            status: stat,
            report: rpt,
            email_ids: emailIds,
            email_message: emailMessage,
          };
          axios
            .post(`${config.base_url}/share_GSTR9Report_email/`, em)
            .then((res) => {
              if (res.data.status) {
                Toast.fire({
                  icon: "success",
                  title: "Shared via mail.",
                });
                setEmailIds("");
                setEmailMessage("");
              }
            })
            .catch((err) => {
              console.log("ERROR=", err);
              if (
                err.response &&
                err.response.data &&
                !err.response.data.status
              ) {
                Swal.fire({
                  icon: "error",
                  title: `${err.response.data.message}`,
                });
              }
            });
        }
      }
    }
  }
  function toggleContent() {
    var contentDiv = document.getElementById("contentDiv");
    if (contentDiv.style.display === "block") {
      contentDiv.style.display = "none";
    } else {
      contentDiv.style.display = "block";
      // Position the div just below the button
      // var buttonRect = event.target.getBoundingClientRect();
      // contentDiv.style.top = (buttonRect.bottom + window.scrollY) + "px";
      // contentDiv.style.left = buttonRect.left + "px";
    }
  }

//   useEffect(() => {
//     // Initialize Bootstrap tabs after the component mounts
//     const tabLinks = document.querySelectorAll('.nav-tabs a[data-toggle="tab"]');
//     tabLinks.forEach(tabLink => {
//         tabLink.addEventListener('click', (e) => {
//             e.preventDefault();
//             const targetId = tabLink.getAttribute('href');
//             const targetPane = document.querySelector(targetId);

//             if (!targetPane) return;

//             // Remove active class from all tabs and panes
//             document.querySelectorAll('.tab-pane.active').forEach(pane => {
//                 pane.classList.remove('active');
//             });
//             document.querySelectorAll('.nav-tabs .active').forEach(activeTab => {
//                 activeTab.classList.remove('active');
//             });

//             // Add active class to the target pane and the clicked tab
//             targetPane.classList.add('active');
//             tabLink.parentElement.classList.add('active');
//         });
//     });

//     return () => {
//         // Cleanup event listeners on component unmount
//         tabLinks.forEach(tabLink => {
//             tabLink.removeEventListener('click', () => {});
//         });
//     };
// }, []);

  return (
    <>
      <FinBase />
      <div
        className="page-content mt-0 pt-0"
        id="page-content"
        style={{ backgroundColor: "#2f516f", minHeight: "100vh" }}
      >
        <div className="card radius-15">
          <div className="card-body" style={{ width: "100%" }}>
            <div className="card-title">
              <center>
                {/* <h2 className="text-uppercase" id="headline">
                GSTR-1 REPORT
                </h2> */}
                <div className="text-uppercase" id="headline" style={{ fontSize: '2em', fontWeight: 'bold' }}>
                  GSTR-9 REPORT
                </div>
              </center>
              <hr />
            </div>

            <div className="bar">
              <div className=" left d-flex justify-content-start">
                <div className="position-relative mr-2">
                  <button
                    className="btn btn-secondary"
                    onClick={toggleContent}
                    style={{ width: "fit-content", height: "fit-content" }}
                  >
                    <i className="fas fa-solid fa-gear"></i> Customize Report
                  </button>
                  <div id="contentDiv" className="salescontent">
                    <h6>Customize Report</h6>
                    <form
                      onSubmit={handleCustomize}
                      className="form reportCustomizeForm px-1"
                      method="get"
                    >
                      <div className="px-2 w-100">
                        <label style={{ textAlign: "left" }}>From</label>
                        <br />
                        <input
                          className="inputdate form-control"
                          type="date"
                          name="start_date"
                          id="startDate"
                          value={startDate}
                          onChange={(e) => setStartDate(e.target.value)}
                          required={endDate != "" ? true : false}
                        />
                      </div>
                      <div className="px-2 w-100">
                        <label style={{ textAlign: "left" }}>To</label>
                        <br />
                        <input
                          type="date"
                          className="inputdate form-control"
                          name="end_date"
                          id="endDate"
                          value={endDate}
                          onChange={(e) => setEndDate(e.target.value)}
                          required={startDate != "" ? true : false}
                        />
                      </div>
                    
                     
                      <div className="d-flex px-2 mt-3 mb-4 w-100">
                        <button
                          type="submit"
                          className="btn btn-outline-light w-50"
                          style={{
                            width: "fit-content",
                            height: "fit-content",
                          }}
                        >
                          Run Report
                        </button>
                        <button
                          type="reset"
                          onClick={toggleContent}
                          className="btn btn-outline-light ml-1 w-50"
                          style={{
                            width: "fit-content",
                            height: "fit-content",
                          }}
                        >
                          Cancel
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="right d-md-flex flex-md-row d-flex flex-column">
              <a
                  className="btn btn-outline-secondary text-grey fa fa-file"
                  role="button"
                  id="pdfBtn"
                  onClick={reportPDF}
                  style={{ width: "fit-content", height: "fit-content" }}
                >
                  {" "}
                  &nbsp;PDF
                </a>
                <a
                  className="ml-2 btn btn-outline-secondary text-grey fa fa-print"
                  role="button"
                  id="printBtn"
                  onClick={printSection}
                  style={{ width: "fit-content", height: "fit-content" }}
                >
                  &nbsp;Print
                </a>
                <a
                  className="ml-2 btn btn-outline-secondary text-grey fa fa-table"
                  role="button"
                  id="exportBtn"
                  onClick={ExportToExcel}
                  style={{ width: "fit-content", height: "fit-content" }}
                >
                  &nbsp;Export
                </a>
                <div className="dropdown p-0 nav-item" id="shareBtn">
                  <li
                    className="ml-2 dropdown-toggle btn btn-outline-secondary text-grey fa fa-share-alt"
                    data-toggle="dropdown"
                    style={{
                      height: "fit-content",
                      width: "fit-content",
                    }}
                  >
                    &nbsp;Share
                  </li>
                  <ul
                    className="dropdown-menu"
                    style={{ backgroundColor: "black" }}
                    id="listdiv"
                  >
                    <a
                      href={shareUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <li
                        style={{
                          textAlign: "center",
                          color: "#e5e9ec",
                          cursor: "pointer",
                        }}
                      >
                        WhatsApp
                      </li>
                    </a>
                    <li
                      style={{
                        textAlign: "center",
                        color: "#e5e9ec",
                        cursor: "pointer",
                      }}
                      data-toggle="modal"
                      data-target="#shareToEmail"
                    >
                      Email
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="card radius-15 print-only" id="pdf-card">
          <div className="card-body">
            <div className="container-fluid">
              <div
                id="printReport"
                className="printReportTemplate"
                style={{ display: "block " }}
              >
                <div className="my-5 page" size="A4">
                  <div id="printdiv2">
                    <div className="py-4 bg-secondary">
                      <div className="col-12">
                        <center className="h5 text-white">
                          <b>{otherDetails.cmpName}</b>
                        </center>
                        <center className="h3 text-white">
                          <b>GSTR-9 REPORT</b>
                        </center>
                        {startDate != "" && endDate != "" ? (
                          <center className="h6 text-white">
                            {startDate} {"TO"} {endDate}
                          </center>
                        ) : null}
                      </div>
                    </div>
                    

                    <div className="row px-1 py-1">
                        

                      <div className="col-12">
                      <section className="product-area mt-2 py-1">
                      <table className="table table-responsive-md mt-4 table-hover" id="reportTable1">
                            <thead>
                              <tr className="text-center">

                              <th  className="text-center" >Pt.I</th>
                              <th className="text-center"colspan="2" >Basic Details</th>
                            
                              </tr>
                             
                            </thead>
                            <tbody>

                                        
                                        <tr>
                                            <td>1</td>
                                            <td>Financial Year</td>
                                            <td> {otherDetails.cmpStart_Date}  to {otherDetails.cmpEnd_date} </td>
                                            
                                        </tr>
                                        <tr>
                                            <td>2</td>
                                            <td>GSTIN</td>
                                            <td>{otherDetails.cmpGST_NO}</td>
                                        </tr>
                                        <tr>
                                            <td>3A</td>
                                            <td>Legal Name</td>
                                            <td>{otherDetails.cmpName}</td>

                                        </tr>
                                        <tr>
                                            <td>3B</td>
                                            <td>Trade Name(if any)</td>
                                            <td></td>
                                        </tr>
                                        


                            
                            </tbody>
                          </table>
                        </section>
                        <br />
                       

                     
                    <div className="row px-1 py-1">
                      <div className="col-12">
                        <section className="product-area mt-2 py-1">
                          <table className="table table-responsive-md mt-4 table-hover" id="reportTable2">
                          <thead> 
                                                    <tr >
                                                        <th className="text-center ">Pt.II</th>
                                                        <th className="text-center " colspan="6">Details Of Outward
                                                            And
                                                            Inward
                                                            Supplies During The Financial Year</th>

                                                    </tr>



                                                    <tr>
                                                        <th colspan="1" className="text-center "></th>

                                                        <th colspan="2" className="text-center "></th>

                                                        <th colspan="4" className="text-center ">(Amount in Rupees in
                                                            All
                                                            Tables)
                                                        </th>





                                                    </tr>
                                               
                                                
                                                    <tr>
                                                        <td></td>
                                                        <th className="text-center ">Nature of Supplies </th>
                                                        <td className="text-center ">Taxable Value</td>

                                                        <td className="text-center ">Central Tax</td>
                                                        <td className="text-center "> State Tax/ UT Tax</td>
                                                        <td className="text-center ">Integrated Tax</td>
                                                        <td className="text-center ">Cess</td>
                                                        </tr>

                                                    </thead>


                                                 
                            <tbody>
                            <tr>
                                                        <td></td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>

                                                    </tr>
                                                    <tr >
                                                        <th className="text-center ">4</th>
                                                        <th className="text-center " colspan="6">Details of advances,
                                                            inward and outward supplies on which tax is payable
                                                            as declared in returns filed during the financial
                                                            year</th>

                                                    </tr>
                                                   
                                                    <tr>
                                                        <td>A</td>
                                                        <td className="text-left ">Supplies made to un-registered persons(B2C)</td>
                                                        <td>{otherDetails.total_tax_b2c}</td>
                                                        <td>{otherDetails.total_cgst_b2c}</td>
                                                        <td>{otherDetails.total_sgst_b2c}</td>
                                                        <td>{otherDetails.total_igst_b2c}</td>
                                                        <td>0</td>

                                                    </tr>
                                                    <tr>
                                                        <td>B</td>
                                                        <td className="text-left ">Supplies made to registered persons(B2B)</td>
                                                        <td>{otherDetails.total_tax_b2b}</td>
                                                        <td>{otherDetails.total_cgst_b2b}</td>
                                                        <td>{otherDetails.total_sgst_b2b}</td>
                                                        <td>{otherDetails.total_igst_b2b}</td>
                                                        <td>0</td>
                                                    </tr>
                                                    <tr>
                                                        <td>C</td>
                                                        <td  className="text-left ">Zero rated supply(Export) on payment of tax(except
                                                            supplies to SEZs)
                                                        </td>

                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>

                                                    </tr>
                                                    <tr>
                                                        <td>D</td>
                                                        <td  className="text-left ">Supplies to SEZs on payment of tax
                                                        </td>

                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>

                                                    </tr>
                                                    <tr>
                                                        <td>E</td>
                                                        <td  className="text-left ">Deemed Exports
                                                        </td>

                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>

                                                    </tr>
                                                    <tr>
                                                        <td>F</td>
                                                        <td  className="text-left ">Advances on which tax has been paid but invoice has
                                                            not
                                                            been issued
                                                            (not cover under (A) to (E) above)
                                                        </td>

                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>

                                                    </tr>
                                                    <tr>
                                                        <td>G</td>
                                                        <td  className="text-left ">Inward supplies on which tax is to be paid on
                                                            reverse
                                                            charge basis
                                                        </td>

                                                        <td>{otherDetails.unregistered_party_tax_amount }</td>
                                                        <td>{otherDetails.unregistered_party_cgst }</td>
                                                        <td>{otherDetails.unregistered_party_sgst}</td>
                                                        <td>{otherDetails.unregistered_party_igst }</td>

                                                        <td>0</td>

                                                    </tr>
                                                    <tr>
                                                        <td>H</td>
                                                        <td  className="text-left ">Sub-total (A to G above)
                                                        </td>

                                                        <td>{otherDetails.sub_total_tax }</td>
                                                        <td>{otherDetails.sub_total_tax_cgst }</td>
                                                        <td>{otherDetails.sub_total_tax_sgst}</td>
                                                        <td>{otherDetails.sub_total_tax_igst }</td>

                                                        <td>0</td>

                                                    </tr>
                                                    <tr>
                                                        <td>I</td>
                                                        <td  className="text-left "> Credit Notes issued in respect of transactions
                                                            specified in (B) to
                                                            (E) above (-)
                                                        </td>

                                                        <td>{otherDetails.total_tax_cnote }</td>
                                                        <td>{otherDetails.total_cgst_cnote }</td>
                                                        <td>{otherDetails.total_sgst_cnote}</td>
                                                        <td>{otherDetails.total_igst_cnote }</td>

                                                        <td>0</td>

                                                    </tr>
                                                    <tr>
                                                        <td>J</td>
                                                        <td  className="text-left ">Debit Notes issued in respect of transactions
                                                            specified
                                                            in (B) to
                                                            (E) above (+)
                                                        </td>
                                                        <td>{otherDetails.total_tax_dnote }</td>
                                                        <td>{otherDetails.total_cgst_dnote }</td>
                                                        <td>{otherDetails.total_sgst_dnote}</td>
                                                        <td>{otherDetails.total_igst_dnote }</td>
                                                        <td>0</td>

                                                    

                                                    </tr>
                                                    <tr>
                                                        <td>K</td>
                                                        <td  className="text-left ">Supplies/tax declared through Amendments(+)
                                                        </td>

                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>

                                                    </tr>
                                                    <tr>
                                                        <td>L</td>
                                                        <td  className="text-left ">Supplies/tax reduced through Amendments(+)
                                                        </td>

                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>

                                                    </tr>
                                                    <tr>
                                                        <td>M</td>
                                                        <td  className="text-left ">Sub-total (I to L above)
                                                        </td>

                                                        <td>{otherDetails.Sub_totalc_d }</td>
                                                        <td>{otherDetails.Sub_totalcgstc_d }</td>
                                                        <td>{otherDetails.Sub_totalsgstc_d}</td>
                                                        <td>{otherDetails.Sub_totaligstc_d }</td>
                                                        <td>0</td>

                                                    </tr>
                                                    <tr>
                                                        <td>N</td>
                                                        <td  className="text-left "> Supplies and advances on which tax is to be paid
                                                            (H+M)
                                                            above
                                                        </td>

                                                        <td>{otherDetails.Sub_total }</td>
                                                        <td>{otherDetails.Sub_totalcgst }</td>
                                                        <td>{otherDetails.Sub_totalsgst}</td>
                                                        <td>{otherDetails.Sub_totaligst }</td>
                                                        <td>0</td>

                                                    </tr>
                                                    <tr >
                                                        <th className="text-center ">5</th>
                                                        <th className="text-center " colspan="6">Details of
                                                                Outward supplies on which tax
                                                                is not
                                                                payable as declared in returns filed during the
                                                                financial year</th>

                                                    </tr>
                                                                        
                                                   

                                                    <tr>
                                                        <td>A</td>
                                                        <td  className="text-left "> Zero rated supply (Export) without payment of tax
                                                        </td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>

                                                    </tr>
                                                    <tr>
                                                        <td>B</td>
                                                        <td  className="text-left ">Supply to SEZs without payment of tax</td>

                                                        <td></td>

                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>

                                                    </tr>
                                                    <tr>
                                                        <td>C</td>
                                                        <td  className="text-left ">Supplies on which tax is to be paid by the recipient
                                                            on
                                                            reverse
                                                            charge basis
                                                        </td>

                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>

                                                    </tr>
                                                    <tr>
                                                        <td>D</td>
                                                        <td  className="text-left ">Exempted
                                                        </td>

                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>

                                                    </tr>
                                                    <tr>
                                                        <td>E</td>
                                                        <td  className="text-left ">Nil Rated
                                                        </td>

                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>

                                                    </tr>
                                                    <tr>
                                                        <td>F</td>
                                                        <td  className="text-left ">Non-GST supply
                                                        </td>

                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>

                                                    </tr>
                                                    <tr>
                                                        <td>G</td>
                                                        <td  className="text-left ">Sub-total (A to F above)
                                                        </td>

                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>

                                                    </tr>
                                                    <tr>
                                                        <td>H</td>
                                                        <td  className="text-left ">Credit Notes issued in respect of transactions
                                                            specified
                                                            in A to F
                                                            above (-)
                                                        </td>

                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>

                                                    </tr>
                                                    <tr>
                                                        <td>I</td>
                                                        <td  className="text-left ">Debit Notes issued in respect of transactions
                                                            specified
                                                            in A to F
                                                            above (+)
                                                        </td>

                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>

                                                    </tr>
                                                    <tr>
                                                        <td>J</td>
                                                        <td  className="text-left ">Supplies declared through Amendments(+)
                                                        </td>

                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>

                                                    </tr>
                                                    <tr>
                                                        <td>K</td>
                                                        <td  className="text-left ">Supplies reduced through Amendments(+)
                                                        </td>

                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>

                                                    </tr>
                                                    <tr>
                                                        <td>L</td>
                                                        <td  className="text-left ">Sub-Total (H to K above)
                                                        </td>

                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>

                                                    </tr>
                                                    <tr>
                                                        <td>M</td>
                                                        <td  className="text-left ">Turnover on which tax is not to be paid (G + L
                                                            above)
                                                        </td>

                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>

                                                    </tr>
                                                    <tr>
                                                        <td>N</td>
                                                        <td  className="text-left ">Total Turnover (including advances) (4N + 5M - 4G
                                                            above)
                                                        </td>


                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>

                                                    </tr>
                                                </tbody>
                          </table>
                        </section>
                        </div>
                        </div>
                        <br />
                        <div className="row px-1 py-1">
                      <div className="col-12">
                        <section className="product-area mt-2 py-1">
                          <table className="table table-responsive-md mt-4 table-hover" id="reportTable2">
                          <thead> 
                            <tr >
                            <th>Pt.III</th>
                                                                <th  colspan="6">Details Of Outward
                                                                    And
                                                                    Inward
                                                                    Supplies During The Financial Year</th>
                            </tr>
                            <tr>
                                                                <td ></td>
                                                                <td >Description </td>
                                                                <td>Type</td>
                                                                <td >Central Tax</td>
                                                                <td>State Tax/ UT Tax</td>
                                                                <td>Integrated Tax</td>
                                                                <td >Cess</td>
                                                            </tr>
                        </thead>
                        <tbody>
                        <tr>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                            </tr>
                                                                <tr>
                                                                    <th colspan="1">6</th>
                                                                    <th colspan="6" class="text-center">Details of ITC
                                                                        availed as declared in
                                                                        returns filed
                                                                        during the financial year</th>
                                                                </tr>
                                                            <tr>
                                                                <td>A</td>
                                                                <td  className="text-left " colspan="2">Total amount of input tax credit availed
                                                                    through FORM
                                                                    GSTR-3B (sum total of Table 4A of FORM GSTR-3B)</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>


                                                            </tr>
                                                            
                                                            <tr>
                                                                <td rowSpan="3">B</td>
                                                                <td  className="text-left " rowSpan="3">Inward supplies (other than imports and inward
                                                                    supplies
                                                                    liable to
                                                                    reverse charge but includes service received from
                                                                    SEZs)
                                                                </td>
                                                                
                                                                
                                                                <td>Inputs</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                            </tr>
                                                            <tr>

                                                                <td>Capital Goods</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>

                                                            </tr>
                                                            <tr>
                                                                
                                                                <td>Input Services</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                            </tr>
                                                           
                                                            <tr>
                                                                <td rowSpan="3">C</td>
                                                                <td  className="text-left " rowSpan="3">Inward supplies received from unregistered persons
                                                                    liable to reverse
                                                                    charge (other than B above) on which tax is paid &
                                                                    ITC
                                                                    availed
                                                                </td>

                                                                <td>Inputs</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                            </tr>
                                                            <tr>

                                                                <td>Capital Goods</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>

                                                            </tr>
                                                            <tr>
                                                                
                                                                <td>Input Services</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                            </tr>
                                                            <tr>
                                                                <td rowSpan="3">D</td>
                                                                <td  className="text-left "  rowSpan="3"> Inward supplies received from registered persons
                                                                    liable
                                                                    to reverse
                                                                    charge (other than B above) on which tax is paid and
                                                                    ITC
                                                                    availed
                                                                </td>

                                                                <td>Inputs</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                            </tr>
                                                            <tr>

                                                                <td>Capital Goods</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>

                                                            </tr>
                                                            <tr>
                                                                
                                                                <td>Input Services</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                            </tr>
                                                            <tr>
                                                                <td rowSpan="2">E</td>
                                                                <td  className="text-left " rowSpan="2"> Import of goods (including suppliesfrom SEZs)
                                                                </td>

                                                                <td>Inputs</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                            </tr>
                                                            <tr>

                                                                <td>Capital Goods</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>

                                                            </tr>
                                                            

                                                            <tr>
                                                                <td>F</td>
                                                                <td  className="text-left " colspan="2"> Import of services (excluding inward
                                                                    supplies from
                                                                    SEZs)
                                                                </td>

                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>

                                                            </tr>
                                                            <tr>
                                                                <td>G</td>
                                                                <td  className="text-left " colspan="2">Input Tax credit received from ISD
                                                                </td>

                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>

                                                            </tr>
                                                            <tr>
                                                                <td>H</td>
                                                                <td  className="text-left " colspan="2">Amount of ITC reclaimed (other than B
                                                                    above)
                                                                    under the
                                                                    provisons of Act
                                                                </td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                            </tr>
                                                            <tr>
                                                                <td>I</td>
                                                                <td  className="text-left " colspan="2">Sub-total (B to H above)
                                                                </td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                            </tr>
                                                            <tr>
                                                                <td>J</td>
                                                                <td   className="text-left " colspan="2">Difference (I - A above)
                                                                </td>

                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>

                                                            </tr>
                                                            <tr>
                                                                <td>K</td>
                                                                <td className="text-left " colspan="2"> Transition credit through TRAN-I
                                                                    (including
                                                                    revisions
                                                                    if any)
                                                                </td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>

                                                            </tr>
                                                            <tr>
                                                                <td>L</td>
                                                                <td  className="text-left " colspan="2">Transition credit through TRAN-II
                                                                </td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>

                                                            </tr>
                                                            <tr>
                                                                <td>M</td>
                                                                <td  className="text-left " colspan="2">Any other ITC availed but not specified
                                                                    above
                                                                </td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>

                                                            </tr>
                                                            <tr>
                                                                <td>N</td>
                                                                <td  className="text-left " colspan="2"> Sub-total (K to M above)</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                            </tr>
                                                            <tr>
                                                                <td>O</td>
                                                                <td  className="text-left " colspan="2">Total ITC availed (I + N above)</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>

                                                            </tr>
                                                            <th colspan="7"></th>

                                                                <tr>
                                                                    <th>7</th>
                                                                    <th colspan="6" class="text-center">Details of ITC
                                                                        Reversed and Ineligible
                                                                        ITC as
                                                                        declared in rerurns filed during the financial
                                                                        year
                                                                    </th>
                                                                </tr>
                                                            <tr>
                                                                <td>A</td>
                                                                <td  className="text-left " colspan="2">As per Rule 37 </td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>



                                                            </tr>

                                                            <tr>
                                                                <td>B</td>
                                                                <td  className="text-left " colspan="2">As per Rule 39</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>


                                                            </tr>


                                                            <tr>
                                                                <td>C</td>
                                                                <td   className="text-left "colspan="2">As per Rule 42
                                                                </td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>

                                                            </tr>

                                                            <tr>
                                                                <td>D</td>
                                                                <td  className="text-left "  colspan="2">As per Rule 43
                                                                </td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>

                                                            </tr>

                                                            <tr>
                                                                <td>E</td>
                                                                <td   className="text-left " colspan="2"> As per section 17(5)
                                                                </td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>


                                                            </tr>
                                                            <tr>
                                                                <td>F</td>
                                                                <td   className="text-left "colspan="2">Reversal of TRAN-I credit
                                                                </td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>

                                                            </tr>
                                                            <tr>
                                                                <td>G</td>
                                                                <td  className="text-left " colspan="2"> Reversal of TRAN-II credit
                                                                </td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>

                                                            </tr>
                                                            <tr>
                                                                <td>H</td>

                                                                <td  className="text-left " colspan="2">Other reversals (pl. specify)</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>

                                                            </tr>
                                                            <tr>
                                                                <td>I</td>
                                                                <td  className="text-left " colspan="2">Total ITC reversed (A to H above)</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>

                                                            </tr>
                                                            <tr>
                                                                <td>J</td>
                                                                <td  className="text-left " colspan="2">Net ITC Available for Utilization (6O
                                                                    -7I)
                                                                </td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>

                                                            </tr>
                                                            <th colspan="7"></th>

                                                                <tr>
                                                                    <th>8</th>
                                                                    <th colspan="6" class="text-center">Other ITC
                                                                        related information</th>
                                                                </tr>
                                                            <tr>
                                                                <td>A</td>
                                                                <td  className="text-left " colspan="2">ITC as per GSTR-2A (Table 3 & 5 thereof)
                                                                </td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>


                                                            </tr>

                                                            <tr>
                                                                <td>B</td>
                                                                <td   className="text-left " colspan="2">ITC as per sum total of 6(B) and 6(H)
                                                                    above
                                                                </td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>


                                                            </tr>


                                                            <tr>
                                                                <td>C</td>
                                                                <td  className="text-left " colspan="2">ITC on inward supplies (other than
                                                                    imports
                                                                    and inward
                                                                    supplies liable to reverse charge but includes
                                                                    services
                                                                    received
                                                                    from SEZs) received during 2017-18 but availed
                                                                    during
                                                                    April to
                                                                    September, 2018
                                                                </td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>

                                                            </tr>

                                                            <tr>
                                                                <td>D</td>
                                                                <td   className="text-left "colspan="2">Difference [A-(B+C)]
                                                                </td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>

                                                            </tr>

                                                            <tr>
                                                                <td>E</td>
                                                                <td  className="text-left " colspan="2"> ITC available but not availed (out of
                                                                    D)
                                                                </td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>


                                                            </tr>
                                                            <tr>
                                                                <td>F</td>
                                                                <td   className="text-left "colspan="2">ITC available but ineligible (out of D)
                                                                </td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>

                                                            </tr>
                                                            <tr>
                                                                <td>G</td>
                                                                <td  className="text-left " colspan="2">IGST paid on import of goods (as per
                                                                    6(E)
                                                                    above)
                                                                </td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>

                                                            </tr>
                                                            <tr>
                                                                <td>H</td>

                                                                <td   className="text-left " colspan="2">IGST credit availed on import of goods
                                                                    (as
                                                                    per 6(E)
                                                                    above)</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>

                                                            </tr>
                                                            <tr>
                                                                <td>I</td>
                                                                <td  className="text-left " colspan="2">Difference (G -H)</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>

                                                            </tr>
                                                            <tr>
                                                                <td>J</td>
                                                                <td  className="text-left " colspan="2">ITC available but not availed on import
                                                                    of
                                                                    goods (Equal
                                                                    to I)</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>

                                                            </tr>
                                                            <tr>
                                                                <td>K</td>
                                                                <td  className="text-left " colspan="2">Total ITC to be lapsed in current
                                                                    financial
                                                                    year (E+F+J)
                                                                </td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>

                                                            </tr>





                                                        </tbody>

                          </table>
                          </section>
                          </div>
                          </div>
                          <div className="row px-1 py-1">
                      <div className="col-12">
                        <section className="product-area mt-2 py-1">
                          <table className="table table-responsive-md mt-4 table-hover" id="reportTable2">
                          <thead>
                                                            <tr >
                                                                <th >Pt.IV</th>
                                                                <th  colspan="7">Details Of Tax Paid
                                                                    As
                                                                    Declared In
                                                                    Returns Filed During The Financial Year</th>

                                                            </tr>
                                                            <tr>
                                                                <td rowspan="11" className="text-center  "> 9</td>
                                                                <td className="text-center  "  rowspan="2">Description
                                                                </td>
                                                                <td className="text-center " rowspan="2">Tax payable
                                                                </td>
                                                                <td className="text-center  " rowspan="2">Paid Trough Cash
                                                                </td>
                                                                <td className="text-center  " colspan="4"> Paid through ITC</td>

                                                            </tr>
                                                            <tr>
                                                                <td className="text-center  ">Central Tax</td>
                                                                <td className="text-center  ">State Tax/ UT Tax</td>
                                                                <td className="text-center  ">Integrated Tax</td>
                                                                <td className="text-center ">Cess</td>


                                                            </tr>


                                                        </thead>
                                                        <tbody id="items-table-body">
                                  
                                 
                                                            <tr>
                                                                <td></td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>


                                                            </tr>
                                                            <tr>
                                                                <td></td>
                                                                <td>Integrated Tax</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                            </tr>
                                                            <tr>
                                                                <td></td>
                                                                <td>Central Tax</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                            </tr>
                                                            <tr>
                                                                <td></td>
                                                                <td>State/ UT tax</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                            </tr>
                                                            <tr>
                                                                <td></td>
                                                                <td>Cess</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                            </tr>
                                                            <tr>
                                                                <td></td>
                                                                <td>Interest</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                            </tr>
                                                            <tr>
                                                                <td></td>
                                                                <td>Late fee</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                            </tr>
                                                            <tr>
                                                                <td></td>
                                                                <td>Penalty</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                            </tr>
                                                            <tr>
                                                                <td></td>
                                                                <td>Other</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                            </tr>

                                                        </tbody>
                          </table>
                          </section>
                          </div>
                          </div>

                          <div className="row px-1 py-1">
                      <div className="col-12">
                        <section className="product-area mt-2 py-1">
                          <table className="table table-responsive-md mt-4 table-hover" id="reportTable2">
                          <thead> 
                                                            <tr >
                                                                <th >Pt.V</th>
                                                                <th colspan="7">Particulars Of The
                                                                    Transactions For
                                                                    The Previous FY Declared In Returns Of April To
                                                                    September Of Current
                                                                    FY Or Upto Date Of Filing Of Annual Return Of
                                                                    Previous
                                                                    FY Whichever
                                                                    Is Earlier</th>

                                                            </tr>
                                                            <tr>
                                                                <td rowspan="2"  className="text-center "></td>
                                                                <td  className="text-center">Description </td>
                                                                <td className="text-center">Tax Payable </td>
                                                                <td className="text-center ">Central Tax </td>
                                                                <td className="text-center">State Tax/ UT Tax</td>
                                                                <td className="text-center">Integrated Tax </td>
                                                                <td className="text-center">Cess</td>


                                                            </tr>


                                                        </thead>
                                                        <tbody id="items-table-body">
                                    
                                    <tr>
                                        <td></td>
                                        <td>0</td>
                                        <td>0</td>
                                        <td>0</td>
                                        <td>0</td>
                                        <td>0</td>
                                        <td>0</td>

                                    </tr>
                                    <tr>
                                        <td>10</td>
                                        <td  className="text-left ">Supplies/tax declared through Amendments (+) (net of
                                            debit notes)
                                        </td>
                                        <td>0</td>
                                        <td>0</td>
                                        <td>0</td>
                                        <td>0</td>
                                        <td>0</td>

                                    </tr>
                                    <tr>
                                        <td>11</td>
                                        <td  className="text-left ">Supplies/tax reduced through Amendments (+) (net of
                                            credit notes)
                                        </td>
                                        <td>0</td>
                                        <td>0</td>
                                        <td>0</td>
                                        <td>0</td>
                                        <td>0</td>
                                    </tr>
                                    <tr>
                                        <td>12</td>
                                        <td  className="text-left ">Reversal of ITC availed during previous financial
                                            year
                                        </td>
                                        <td>0</td>
                                        <td>0</td>
                                        <td>0</td>
                                        <td>0</td>
                                        <td>0</td>
                                    </tr>
                                    <tr>
                                        <td>13</td>
                                        <td  className="text-left ">ITC availed for the previous financial year</td>
                                        <td>0</td>
                                        <td>0</td>
                                        <td>0</td>
                                        <td>0</td>
                                        <td>0</td>
                                    </tr>
                                </tbody>

                          </table>
                        </section>
                        </div>
                          </div>

                          <div className="row px-1 py-1">
                      <div className="col-12">
                        <section className="product-area mt-2 py-1">
                          <table className="table table-responsive-md mt-4 table-hover" id="reportTable2">
                          <thead> 
                                                            <tr >
                                                                <th >14</th>
                                                                <th colspan="3">Differential Tax
                                                                    Paid On
                                                                    Account Of
                                                                    Declaration In 10 & 11 Above</th>

                                                            </tr>
                                                            
                                                           


                                                        </thead>
                                                        <tbody id="items-table-body">
                            <tr>
                                <td  className="text-left "></td>
                                <td  className="text-left ">Description </td>
                                <td className="text-center">Payable </td>
                                <td className="text-center">Paid</td>


                            </tr>
                            <tr>
                            <td ></td>
                                <td  className="text-left ">0</td>
                                <td>0</td>
                                <td>0</td>

                            </tr>
                            <tr>
                            <td ></td>
                                <td  className="text-left ">Integrated Tax</td>
                                <td>0</td>
                                <td>0</td>
                            </tr>

                            <tr>
                            <td ></td>
                                <td  className="text-left ">Central Tax</td>
                                <td>0</td>
                                <td>0</td>

                            </tr>
                            <tr>
                            <td ></td>
                                <td  className="text-left ">State/ UT Tax</td>
                                <td>0</td>
                                <td>0</td>

                            </tr>
                            <tr>
                            <td ></td>
                                <td  className="text-left ">Cess</td>
                                <td>0</td>
                                <td>0</td>

                            </tr>
                            <tr>
                            <td ></td>
                                <td  className="text-left ">Interest</td>
                                <td>0</td>
                                <td>0</td>

                            </tr>

                        </tbody> 

                          </table>
                          </section>
                          </div>
                          </div>

                          <div className="row px-1 py-1">
                      <div className="col-12">
                        <section className="product-area mt-2 py-1">
                          <table className="table table-responsive-md mt-4 table-hover" id="reportTable2">
                          <thead> 
                                                            <tr >
                                                                <th >Pt. VI</th>
                                                                <th colspan="9">Other Information</th>

                                                            </tr>
                                                            <tr>
                                                                <th  className="text-center ">15</th>
                                                                <th  className="text-center" colspan="9">Particulars of
                                                                    Demands
                                                                    and Refunds
                                                                </th>
                                                            </tr>
                                                            <tr>
                                                                <td rowspan="2" className="text-center " ></td>
                                                                <td  className="text-center">Details</td>
                                                                <td className="text-center ">Central Tax </td>
                                                                <td className="text-center ">State Tax/ UT Tax</td>
                                                                <td className="text-center">Integrated Tax</td>
                                                                <td className="text-center ">Cess </td>
                                                                <td className="text-center ">Interest</td>
                                                                <td className="text-center ">Penalty </td>
                                                                <td className="text-center">Late Fee/ Others</td>

                                                            </tr>


                                                        </thead>
                                                        <tbody id="items-table-body">
                                                 
                                                 <tr>
                                                     <th></th>
                                                     <td>0</td>
                                                     <td>0</td>
                                                     <td>0</td>
                                                     <td>0</td>
                                                     <td>0</td>
                                                     <td>0</td>
                                                     <td>0</td>
                                                     <td>0</td>

                                                 </tr>
                                                 <tr>

                                                     <td>A</td>
                                                     <td  className="text-left ">Total Refund claimed</td>
                                                     <td>0</td>
                                                     <td>0</td>
                                                     <td>0</td>
                                                     <td>0</td>
                                                     <td>0</td>
                                                     <td>0</td>
                                                     <td>0</td>
                                                 </tr>

                                                 <tr>

                                                     <td>B</td>
                                                     <td  className="text-left ">Total Refund sanctioned</td>
                                                     <td>0</td>
                                                     <td>0</td>
                                                     <td>0</td>
                                                     <td>0</td>
                                                     <td>0</td>
                                                     <td>0</td>
                                                     <td>0</td>
                                                 </tr>
                                                 <tr>

                                                     <td>C</td>
                                                     <td  className="text-left ">Total Refund rejected</td>
                                                     <td>0</td>
                                                     <td>0</td>
                                                     <td>0</td>
                                                     <td>0</td>
                                                     <td>0</td>
                                                     <td>0</td>
                                                     <td>0</td>
                                                 </tr>
                                                 <tr>

                                                     <td>D</td>
                                                     <td  className="text-left ">Total Refund pending</td>
                                                     <td>0</td>
                                                     <td>0</td>
                                                     <td>0</td>
                                                     <td>0</td>
                                                     <td>0</td>
                                                     <td>0</td>
                                                     <td>0</td>
                                                 </tr>
                                                 <tr>

                                                     <td>E</td>
                                                     <td  className="text-left ">Total demand of taxes</td>
                                                     <td>0</td>
                                                     <td>0</td>
                                                     <td>0</td>
                                                     <td>0</td>
                                                     <td>0</td>
                                                     <td>0</td>
                                                     <td>0</td>
                                                 </tr>
                                                 <tr>

                                                     <td>F</td>
                                                     <td  className="text-left ">Total taxes paid in respect of E above</td>
                                                     <td>0</td>
                                                     <td>0</td>
                                                     <td>0</td>
                                                     <td>0</td>
                                                     <td>0</td>
                                                     <td>0</td>
                                                     <td>0</td>
                                                 </tr>
                                                 <tr>

                                                     <td>G</td>
                                                     <td  className="text-left ">Total demands pending out of E above</td>
                                                     <td>0</td>
                                                     <td>0</td>
                                                     <td>0</td>
                                                     <td>0</td>
                                                     <td>0</td>
                                                     <td>0</td>
                                                     <td>0</td>
                                                 </tr>


                                             </tbody>

                          </table>
                          </section>
                          </div>
                          </div>

                          <div className="row px-1 py-1">
                      <div className="col-12">
                        <section className="product-area mt-2 py-1">
                          <table className="table table-responsive-md mt-4 table-hover" id="reportTable2">
                          <thead> 
                                                            <tr >
                                                                <th  className="text-center ">16</th>
                                                                <th  className="text-center " colspan="7">Information On
                                                                    Supplies
                                                                    Received
                                                                    From Composition Taxpayers, Deemed Supply Under
                                                                    Section
                                                                    143 And
                                                                    Goods Sent On Approval Basis</th>

                                                            </tr>
                                                            <tr>
                                                                <td rowspan="2" className="text-center"></td>
                                                                <td className="text-center">Description </td>
                                                                <td className="text-center ">Taxable Value </td>
                                                                <td className="text-center ">Central Tax</td>
                                                                <td className="text-center">Integrated Tax </td>
                                                                <td className="text-center ">Taxable Value </td>
                                                                <td className="text-center">Cess</td>


                                                            </tr>


                                                        </thead>
                                                        <tbody id="items-table-body">
                                                
                                                            <tr>
                                                                <td></td>
                                                                <td  className="text-left ">0</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>


                                                            </tr>
                                                            <tr>
                                                                <td>A</td>
                                                                <td  className="text-left ">Supplies received from composition taxpayers</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                            </tr>

                                                            <tr>
                                                                <td>B</td>
                                                                <td  className="text-left ">Deemed supply under section 143</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                            </tr>

                                                            <tr>
                                                                <td>C</td>
                                                                <td  className="text-left ">Goods sent on approval basis but not returned</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                                <td>0</td>
                                                            </tr>



                                                        </tbody>
                                                    </table>
                                                    </section>
                                                    </div>
                          </div>

                          <div className="row px-1 py-1">
                      <div className="col-12">
                        <section className="product-area mt-2 py-1">
                          <table className="table table-responsive-md mt-4 table-hover" id="reportTable2">
                          <thead> 
                                                            <tr >
                                                                <th className="text-center ">17</th>
                                                                <th  className="text-center " colspan="9">HSN Wise Summary Of
                                                                    Outward Supplies
                                                                </th>

                                                            </tr>
                                                            <tr>

                                                                <td className="text-center">HSN Code </td>
                                                                <td className="text-center ">UQC </td>
                                                                <td className="text-center ">Total Quantity</td>
                                                                <td className="text-center ">Taxable Value </td>
                                                                <td className="text-center ">Rate of Tax </td>
                                                                <td className="text-center ">Central Tax</td>
                                                                <td className="text-center ">State/ UT Tax </td>
                                                                <td className="text-center ">Integrated Tax </td>
                                                                <td className="text-center">Cess</td>

                                                            </tr>


                                                        </thead>
                                                        <tbody id="items-table-body">
                                                        {reportData.map((item, index) => (
                  <tr className="text-center" key={index}>
                    <td style={{ textAlign: 'center', color: 'black' }}>{item.type}</td>
                    <td style={{ textAlign: 'center', color: 'black' }}>{item.hsn}</td>
                    <td style={{ textAlign: 'center', color: 'black' }}>{item.qty}</td>
                    <td style={{ textAlign: 'center', color: 'black' }}>{item.subtotal}</td>
                    <td style={{ textAlign: 'center', color: 'black' }}>{item.tax}</td>
                    <td style={{ textAlign: 'center', color: 'black' }}>{item.cgst}</td>
                    <td style={{ textAlign: 'center', color: 'black' }}>{item.sgst}</td>
                    <td style={{ textAlign: 'center', color: 'black' }}>{item.igst}</td>

                                                                  
                                                                <td>0</td>

                                                                </tr>
                ))}
                                                        </tbody>
                                                    </table>
                                                    {reportData.length == 0 ? (
                            <center>
                              <h4 className="text-dark">No data available.!</h4>
                            </center>
                          ) : null}
                                                    </section>

                                                    </div>
                          </div>

                          <div className="row px-1 py-1">
                      <div className="col-12">
                        <section className="product-area mt-2 py-1">
                          <table className="table table-responsive-md mt-4 table-hover" id="reportTable2">
                          <thead> 
                                                            <tr >
                                                                <th className="text-center ">18</th>
                                                                <th className="text-center " colspan="9">HSN Wise Summary Of
                                                                    Inward Supplies
                                                                </th>

                                                            </tr>
                                                            <tr>

                                                                <td className="text-center ">HSN Code </td>
                                                                <td className="text-center ">UQC </td>
                                                                <td className="text-center ">Total Quantity</td>
                                                                <td className="text-center ">Taxable Value </td>
                                                                <td className="text-center ">Rate of Tax </td>
                                                                <td className="text-center ">Central Tax</td>
                                                                <td className="text-center">State/ UT Tax </td>
                                                                <td className="text-center ">Integrated Tax </td>
                                                                <td className="text-center ">Cess</td>

                                                            </tr>


                                                        </thead>
                                                
                                                        <tbody id="items-table-body">
                                                                  {reportpurchaseData.map((item, index) => (
                                                                    <tr className="text-center" key={index}>
                                                                      <td style={{ textAlign: 'center', color: 'black' }}>{item.type || 0}</td>
                                                                      <td style={{ textAlign: 'center', color: 'black' }}>{item.hsn || 0}</td>
                                                                      <td style={{ textAlign: 'center', color: 'black' }}>{item.qty || 0}</td>
                                                                      <td style={{ textAlign: 'center', color: 'black' }}>{item.subtotal || 0}</td>
                                                                      <td style={{ textAlign: 'center', color: 'black' }}>{item.tax || 0}</td>
                                                                      <td style={{ textAlign: 'center', color: 'black' }}>{item.cgst || 0}</td>
                                                                      <td style={{ textAlign: 'center', color: 'black' }}>{item.sgst || 0}</td>
                                                                      <td style={{ textAlign: 'center', color: 'black' }}>{item.igst || 0}</td>
                                                                      <td>0</td>
                                                                    </tr>
                                                                  ))}
                                                        </tbody>
                                                    </table>
                                                    {reportpurchaseData.length == 0 ? (
                            <center>
                              <h4 className="text-dark">No data available.!</h4>
                            </center>
                          ) : null}
                                                    </section>
                                                    </div>
                          </div>

                          <div className="row px-1 py-1">
                      <div className="col-12">
                        <section className="product-area mt-2 py-1">
                          <table className="table table-responsive-md mt-4 table-hover" id="reportTable2">
                          <thead> 
                                                            <tr>
                                                                <th className="text-center ">19</th>
                                                                <th className="text-center" colspan="4">Late Fee Payable And
                                                                    Paid</th>

                                                            </tr>


                                                        </thead>
                                                        <tbody id="items-table-body">
                                                            <tr>
                                                                <td rowspan="2"></td>
                                                                <td className="text-center">Description </td>
                                                                <td className="text-center">Payable </td>
                                                                <td className="text-center">Paid</td>


                                                            </tr>
                                                            <tr>
                                                                <td className="text-center">0</td>
                                                                <td className="text-center">0</td>
                                                                <td className="text-center">0</td>

                                                            </tr>


                                                            <tr>
                                                                <td>A</td>
                                                                <td  className="text-left ">Central Tax</td>
                                                                <td>0</td>
                                                                <td>0</td>

                                                            </tr>
                                                            <tr>
                                                                <td>B</td>
                                                                <td  className="text-left ">State Tax</td>
                                                                <td>0</td>
                                                                <td>0</td>

                                                            </tr>




                                                        </tbody>
                                                    </table>
                                                    </section>

                      </div>

                     </div>

                        <section className="balance-info text-dark pt-1 pb-1">
                          <div className="row p-4">
                            <div className="col-10">
                              <div className="row ">
                                <br/><br/><br/><br/><br/>
                                <div className="col-4 "></div>
                                <div className="col-4"></div>
                              </div>
                            </div>
                          </div>
                        </section>

                      </div>
                    </div>

                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Share To Email Modal --> */}
      <div className="modal fade" id="shareToEmail">
        <div className="modal-dialog modal-lg">
          <div className="modal-content" style={{ backgroundColor: "#213b52" }}>
            <div className="modal-header">
              <h5 className="m-3">Share Report</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form
                onSubmit={handleShareEmail}
                className="needs-validation px-1"
                id="share_to_email_form"
              >
                <div className="card p-3 w-100">
                  <div className="form-group">
                    <label for="emailIds">Email IDs</label>
                    <textarea
                      className="form-control"
                      name="email_ids"
                      id="emailIds"
                      rows="3"
                      placeholder="Multiple emails can be added by separating with a comma(,)."
                      value={emailIds}
                      onChange={(e) => setEmailIds(e.target.value)}
                      required
                    />
                  </div>
                  <div className="form-group mt-2">
                    <label for="item_unitname">Message(optional)</label>
                    <textarea
                      name="email_message"
                      id="email_message"
                      className="form-control"
                      cols=""
                      rows="4"
                      value={emailMessage}
                      onChange={(e) => setEmailMessage(e.target.value)}
                      placeholder="This message will be sent along with Report details."
                    />
                  </div>
                </div>
                <div
                  className="modal-footer d-flex justify-content-center w-100"
                  style={{ borderTop: "1px solid #ffffff" }}
                >
                  <button
                    type="submit"
                    id="share_with_email"
                    className="submitShareEmailBtn w-50 text-uppercase"
                  >
                    SEND MAIL
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default GSTR9;
