import React, { useEffect, useState } from "react";
import FinBase from "../FinBase";
import * as XLSX from "xlsx";
import { Link, useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';
import axios from "axios";
import config from "../../../functions/config";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentDots } from '@fortawesome/free-solid-svg-icons';


function ManualJournal() {
  const navigate = useNavigate();
  const [sortDirection, setSortDirection] = useState('asc');
  const [filter, setFilter] = useState('All');
  function exportToExcel() {
    const Table = document.getElementById("journalTable");
    const ws = XLSX.utils.table_to_sheet(Table);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, "journal.xlsx");
  }
   
  
  function sortTable(columnIndex, isAscending) {
    var table, rows, switching, i, x, y, shouldSwitch;
    table = document.getElementById("journalTable");
    switching = true;

    while (switching) {
      switching = false;
      rows = Array.from(table.querySelectorAll("tbody tr"));

      rows.sort((rowA, rowB) => {
        const cellA = rowA.getElementsByTagName("td")[columnIndex].textContent.trim();
        const cellB = rowB.getElementsByTagName("td")[columnIndex].textContent.trim();
        const valueA = parseFloat(cellA.replace(/[^0-9.-]/g, '')) || 0;
        const valueB = parseFloat(cellB.replace(/[^0-9.-]/g, '')) || 0;
        return isAscending ? valueA - valueB : valueB - valueA;
      });

      // Remove existing rows from the table
      for (i = table.rows.length - 1; i > 0; i--) {
        table.deleteRow(i);
      }

      // Append the sorted rows back to the table
      rows.forEach(row => table.tBodies[0].appendChild(row));
    }
  }

  function handleSort(columnIndex) {
    const direction = sortDirection === 'asc' ? 'desc' : 'asc';
    setSortDirection(direction);
    sortTable(columnIndex, direction === 'asc');
  }
  function sortJournalNumberAscending() {
    var table = document.getElementById("journalTable");
    var rows = Array.from(table.querySelectorAll("tbody tr"));
  
    rows.sort(function (a, b) {
      var journalNoA = a.cells[2].textContent.trim();
      var journalNoB = b.cells[2].textContent.trim();
      return journalNoA.localeCompare(journalNoB);
    });
  
    // Remove existing rows from the table
    while (table.tBodies[0].rows.length > 0) {
      table.tBodies[0].deleteRow(0);
    }
  
    // Append the sorted rows back to the table
    rows.forEach(function (row) {
      table.tBodies[0].appendChild(row);
    });
  }
  
  
  function searchTable(){
    var rows = document.querySelectorAll('#journalTable tbody tr');
    var val = document.getElementById('search').value.trim().replace(/ +/g, ' ').toLowerCase();
    rows.forEach(function(row) {
      var text = row.textContent.replace(/\s+/g, ' ').toLowerCase();
      row.style.display = text.includes(val) ? '' : 'none';
    });
  }

  const ID = Cookies.get('Login_id');
  const [journals, setJournals] = useState([]);

  const fetchJournals = () => {
    axios.get(`${config.base_url}/fetch_journals/${ID}/`).then((res) => {
      if (res.data.status) {
        const jrnls = res.data.journals.map((i) => {
          const debitTotal = parseFloat(i.Debit_Total) || 0;
          const creditTotal = parseFloat(i.Credit_Total) || 0;
          const amount = debitTotal + creditTotal;
          return {
            id: i.id,
            date: i.Journal_Date,
            referenceno: i.Reference_Number,
            journalnumber: i.Journal_Number,
            note: i.Description,
            amount: amount,
            status: i.Status
          };
        });
        setJournals(jrnls);
      }
    }).catch((err) => {
      console.log('ERR', err);
    });
  };
  

  useEffect(()=>{
    fetchJournals();
  },[])



  const filterTable = (columnIndex, filterValue) => {
    var table = document.getElementById("journalTable");
    var rows = table.getElementsByTagName("tr");

    for (var i = 1; i < rows.length; i++) { // Skip header row
      var cell = rows[i].getElementsByTagName("td")[columnIndex];

      if (cell) {
        var cellText = cell.textContent.toLowerCase().trim();
        if (filterValue === 'All' || cellText === filterValue.toLowerCase()) {
          rows[i].style.display = "";
        } else {
          rows[i].style.display = "none";
        }
      }
    }
  };

  // Handle filter change
  const handleFilterChange = (status) => {
    setFilter(status);
    // Assuming '7' is the index of the 'STATUS' column
    filterTable(6, status); // Adjust index as needed
  };
  function refreshAll(){
    setJournals([])
    fetchJournals();
  }
  return (
    <>
      <FinBase />
      <div
        className="page-content"
        style={{ backgroundColor: "#2f516f", minHeight: "100vh" }}
      >
        <div className="card radius-15 h-20">
          <div className="row">
            <div className="col-md-12">
              <center>
                <h2 className="mt-3">MANUAL JOURNALS</h2>
              </center>
              <hr />
            </div>
          </div>
        </div>

        <div className="card radius-15">
          <div className="card-body">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12 col-md-4">
                  <div className="d-flex align-items-center">
                    <input
                      type="text"
                      id="search"
                      className="form-control"
                      placeholder="Search.."
                      autoComplete="off"
                      onKeyUp={searchTable}
                    />
                    <div
                      className="dropdown ml-1"
                      style={{ justifyContent: "left" }}
                    >
                      <button
                        type="button"
                        style={{ width: "fit-content", height: "fit-content" }}
                        className="btn btn-outline-secondary dropdown-toggle text-grey"
                        data-toggle="dropdown"
                      >
                        <i className="fa fa-sort"></i> Sort by
                      </button>
                      <div
                        className="dropdown-menu"
                        style={{ backgroundColor: "black" }}
                      >
                        <a
                          className="dropdown-item"
                          onClick={refreshAll}
                          style={{
                            height: "40px",
                            fontSize: "15px",
                            color: "white",
                          }}
                        >
                          All
                        </a>
                        <a
                           className="dropdown-item"
                           style={{ height: "40px", fontSize: "15px", color: "white", cursor: "pointer" }}
                           onClick={() => handleSort(5)} // Sort by "AMOUNT" column (index 5)
                        >
                         Amount
                        </a>
                        <a
                            className="dropdown-item"
                            style={{ height: "40px", fontSize: "15px", color: "white", cursor: "pointer" }}
                            onClick={sortJournalNumberAscending}
                        >
                         Journal Number
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-2"></div>
                <div className="col-12 col-md-6 d-flex justify-content-center justify-content-md-end flex-wrap">
                  <button
                    type="button"
                    style={{ width: "fit-content", height: "fit-content" }}
                    className="btn btn-outline-secondary text-grey"
                    id="exportBtn"
                    onClick={exportToExcel}
                  >
                    <i className="fa fa-table"></i> Export To Excel
                  </button>
                  <div className="dropdown ml-1">
                    <button
                      type="button"
                      style={{ width: "fit-content", height: "fit-content" }}
                      className="btn btn-outline-secondary dropdown-toggle text-grey"
                      data-toggle="dropdown"
                    >
                      <i className="fa fa-filter"></i> filter by  {filter}
                    </button>
                    <div
                      className="dropdown-menu"
                      style={{ backgroundColor: "black" }}
                    >
                      <a
                        className="dropdown-item"
                        style={{
                       
                          height: "40px",
                          fontSize: "15px",
                          color: "white",
                          cursor: "pointer",
                        }}
                        onClick={() => handleFilterChange('All')}
                      >
                        All
                      </a>
                      <a


                        className="dropdown-item"
                        style={{
                          height: "40px",
                          fontSize: "15px",
                          color: "white",
                          cursor: "pointer",
                        }}
                        onClick={() => handleFilterChange('Draft')}
                      >
                        Draft
                      </a>
                      <a


                        className="dropdown-item"
                        style={{
                          height: "40px",
                          fontSize: "15px",
                          color: "white",
                          cursor: "pointer",
                        }}
                        onClick={() => handleFilterChange('Saved')}
                      >
                        Saved
                      </a>
                    </div>
                  </div>
                  <Link to="/manualjournalcreation" className="ml-1">
                    <button
                      type="button"
                      style={{ width: "fit-content", height: "fit-content" }}
                      className="btn btn-outline-secondary text-grey"
                    >
                      <i className="fa fa-plus font-weight-light"></i> Journal
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="table-responsive">
            <table
              className="table table-responsive-md table-hover mt-4"
              id="journalTable"
              style={{ textAlign: "center" }}
            >
              <thead>
                <tr>
                  <th>SL.NO.</th>
                  <th>DATE</th>
                  <th>JOURNAL.NO</th>
                  <th>REFERENCE NUMBER</th>
                  <th>NOTES</th>
                  <th> AMOUNT</th>
                  <th>STATUS</th>
                 
                </tr>
              </thead>
              <tbody>
                {journals && journals.map((i,index)=>(
                  <tr
                    className="clickable-row"
                    onClick={()=>navigate(`/view_manual_journal/${i.id}/`)}
                    style={{ cursor: "pointer" }}
                  >
                    <td>{index+1}</td>
                    <td>{i.date}</td>
                 
                    <td>{i.journalnumber}</td>
                    <td>{i.referenceno}</td>
                    <td>{i.note ? (
                    <span style={{ alignItems: 'center' }}>
                     
                      {i.note}
                    </span>
                  ) : (
                    <FontAwesomeIcon icon={faCommentDots}  style={{ alignItems: 'center' }} />
                  )}</td>
                    <td>{i.amount}</td>
                   
                    <td>{i.status}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}

export default ManualJournal;

