import React, { useEffect, useState } from "react";
import FinBase from "../FinBase";
import html2pdf from "html2pdf.js";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import axios from "axios";
import Swal from "sweetalert2";
import config from "../../../functions/config";

function UPIStatement() {
  const navigate = useNavigate();
  const ID = Cookies.get("Login_id");
  const [upiPayments, setUpiPayments] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [otherDetails, setOtherDetails] = useState({});
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const fetchUpiPayments = () => {
    axios
      .get(`${config.base_url}/upiPayments/${ID}/`)
      .then((res) => {
        console.log("SO RES=", res);
        if (res.data.status) {
          const reportData = res.data.reportData;
          setUpiPayments(reportData);
          setTotalAmount(res.data.reportData[0].totalAmount);
          setOtherDetails(res.data.otherDetails);
          setStartDate("");
          setEndDate("");

        }
      })
      .catch((err) => {
        console.log("ERR", err);
      });
  };

  useEffect(() => {
    fetchUpiPayments();
  }, []);

  function refreshAll() {
    setUpiPayments([]);
    fetchUpiPayments();
  }

  const currentUrl = window.location.href;
  const shareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
    currentUrl
  )}`;
  
  function printSheet() {
    var divToPrint = document.getElementById("PrintUPI");
    var printWindow = window.open("", "", "height=700,width=1000");

    printWindow.document.write("<html><head><title></title>");
    printWindow.document.write(`
        <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.6.2/dist/css/bootstrap.min.css" integrity="sha384-xOolHFLEh07PJGoPkLv1IbcEPTNtaed2xpHsD9ESMhqIYd0nLMwNLD69Npy4HI+N" crossorigin="anonymous">
        <link rel="preconnect" href="https://fonts.googleapis.com">
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
        <link href="https://fonts.googleapis.com/css2?family=Agbalumo&family=Black+Ops+One&family=Gluten:wght@100..900&family=Playball&display=swap" rel="stylesheet">
    `);
    printWindow.document.write("</head>");
    printWindow.document.write("<body>");
    printWindow.document.write(divToPrint.outerHTML);
    printWindow.document.write("</body>");
    printWindow.document.write("</html>");
    printWindow.document.close();
    printWindow.print();
    printWindow.addEventListener("afterprint", function () {
      printWindow.close();
    });
  }

  function reportPDF() {
    var st = startDate;
    var en = endDate;
    var date = "";
    if (st != "" && en != "") {
      date = `_${startDate}` + "_" + `${endDate}`;
    }
    var element = document.getElementById("PrintUPI");
    var opt = {
      margin: [0.5, 0.3, 0.3, 0.5],
      filename: "UPI_Statement" + date,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "cm", format: "letter", orientation: "portrait" },
    };
    html2pdf().set(opt).from(element).save();
  }
  


  const parseDate = (dateString) => {
      const parts = dateString.split('-');
      return new Date(parts[0], parts[1] - 1, parts[2]); // year, month (0-based), day
  };

  const filterTableByDate = () => {
      if (startDate !== '' && endDate !== '') {
          const start = parseDate(startDate);
          const end = parseDate(endDate);
          const table = document.getElementById('UPIPaymentsTable');
          const rows = table.getElementsByTagName('tr');
          let totalAmount = 0;

          for (let i = 1; i < rows.length; i++) {
              const row = rows[i];
              const rowData = row.getElementsByTagName('td')[0].textContent; // Assuming the first column contains the date
              const rowDate = parseDate(rowData);
              const amountCell = row.getElementsByTagName('td')[5]; // Assuming the 6th column contains the amount
              const amount = parseFloat(amountCell.textContent) || 0;
              const typeCell = row.getElementsByTagName('td')[1]; // Assuming the 2nd column contains the type
              const type = typeCell.textContent.toUpperCase().trim(); // Adjust to the case you use

              if (rowDate >= start && rowDate <= end) {
                  row.style.display = '';
                  switch (type) {
                      case 'INVOICE':
                      case 'RECURRING INVOICE':
                      case 'SALES ORDER':
                      case 'RETAINER INVOICE':
                      case 'DEBIT NOTE':
                      case 'EMPLOYEE LOAN':
                      case 'ADDITIONAL LOAN':
                      case 'EMI PAID':
                          totalAmount += amount;
                          break;
                      case 'CREDIT NOTE':
                      case 'BILL':
                      case 'RECURRING BILL':
                      case 'PURCHASE ORDER':
                      case 'LOAN ACCOUNT':
                      case 'EMPLOYEE ADDITIONAL LOAN':
                      case 'LOAN EMI PAID':
                      case 'EXPENSE':
                          totalAmount -= amount;
                          break;
                  }
              } else {
                  row.style.display = 'none';
              }
          }
          document.getElementById('bal').textContent = totalAmount.toFixed(2);
      } else {
          alert('Select valid Start and End dates');
      }
  };

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    },
  });


  const [emailIds, setEmailIds] = useState("");
  const [emailMessage, setEmailMessage] = useState("");

  function handleShareEmail(e) {
    e.preventDefault();

    var st = startDate;
    var end = endDate;

    if ((st != "" && end == "") || (st == "" && end != "")) {
      alert("Please select both date inputs or select none");
      return;
    } else {
      var emailsString = emailIds.trim();

      var emails = emailsString.split(",").map(function (email) {
        return email.trim();
      });

      var emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;

      var invalidEmails = [];
      if (emailsString === "") {
        alert("Enter valid email addresses.");
      } else {
        for (var i = 0; i < emails.length; i++) {
          var currentEmail = emails[i];

          if (currentEmail !== "" && !emailRegex.test(currentEmail)) {
            invalidEmails.push(currentEmail);
          }
        }

        if (invalidEmails.length > 0) {
          alert("Invalid emails. Please check!\n" + invalidEmails.join(", "));
        } else {
          // document.getElementById("share_to_email_form").submit();
          var em = {
            Id: ID,
            start: st,
            end: end,
            email_ids: emailIds,
            email_message: emailMessage,
          };
          axios
            .post(`${config.base_url}/Share_upistatement/`, em)
            .then((res) => {
              if (res.data.status) {
                Toast.fire({
                  icon: "success",
                  title: "Shared via mail.",
                });
                setEmailIds("");
                setEmailMessage("");
              }
            })
            .catch((err) => {
              console.log("ERROR=", err);
              if (
                err.response &&
                err.response.data &&
                !err.response.data.status
              ) {
                Swal.fire({
                  icon: "error",
                  title: `${err.response.data.message}`,
                });
              }
            });
        }
      }
    }
  }
  function toggleContent() {
    var contentDiv = document.getElementById("contentDiv");
    if (contentDiv.style.display === "block") {
      contentDiv.style.display = "none";
    } else {
      contentDiv.style.display = "block";
      // Position the div just below the button
      // var buttonRect = event.target.getBoundingClientRect();
      // contentDiv.style.top = (buttonRect.bottom + window.scrollY) + "px";
      // contentDiv.style.left = buttonRect.left + "px";
    }
  }


  return (
    <>
      <FinBase />
      <div
        className="page-content"
        style={{ backgroundColor: "#2f516f", minHeight: "100vh" }}
      >
        <Link
          className="d-flex justify-content-end p-2"
          style={{ cursor: "pointer" }}
          to="/UPIPayments"
        >
          <i
            className="fa fa-times-circle text-white"
            style={{ fontSize: "1.2rem" }}
          ></i>
        </Link>
        <div className="card radius-15 h-20">
          <div className="row">
            <div className="col-md-12">
              <center>
                <h2 className="mt-3">UPI PAYMENTS</h2>
              </center>
              <hr />
            </div>
          </div>
        </div>

        

        <div className="card radius-15 print-only" id="pdf-card">
          <div className="card-body">
            <div className="container-fluid">
            <div className="row align-items-center">
            <div className="row justify-content-start">
              
              <div className="col-md-6 d-flex align-items-center justify-content-start">
                <div className="form-inline">
                  <input
                    type="date"
                    name="start_date"
                    id="startDate"
                    className="form-control mr-1"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                    required={endDate != "" ? true : false}
                  />
                  <span className="mx-1">TO</span>
                  <input
                    type="date"
                    name="end_date"
                    id="endDate"
                    className="form-control mr-1"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                    required={startDate != "" ? true : false}
                  />
                  <button
                    className="btn btn-outline-secondary form-control ml-1"
                    type="button"
                    onClick={filterTableByDate}
                  >
                    Filter
                  </button>
                </div>
              </div>
              <div className="col-12 col-md-6 d-flex flex-wrap justify-content-center justify-content-md-end">
                <button
                    type="button"
                    className="btn btn-outline-secondary text-grey ml-1"
                    id="pdfBtn"
                    onClick={reportPDF}
                    style={{ width: "100px", height: "fit-content" }}
                  >
                    <i className="fa fa-file"></i> &nbsp;PDF
                </button>

                <button
                    type="button"
                    className="btn btn-outline-secondary text-grey ml-1"
                    id="printBtn"
                    onClick={printSheet}
                    style={{ width: "100px", height: "fit-content" }}
                  >
                    <i className="fa fa-print"></i> &nbsp;Print
                </button>

                <button
                  type="button"
                  className="dropdown-toggle nav-item btn btn-outline-secondary text-grey ml-1"
                  data-toggle="dropdown"
                  id="shareBtn"
                  style={{ width: "100px", height: "fit-content" }}
                >
                  <i className="fa fa-share-alt"></i> &nbsp;Share
                </button>
                <ul className="dropdown-menu" style={{ backgroundColor: "black" }} id="listdiv">
                  <a href={shareUrl} target="_blank" rel="noopener noreferrer">
                    <li style={{ textAlign: "center", color: "#e5e9ec", cursor: "pointer" }}>
                      WhatsApp
                    </li>
                  </a>
                  <li
                    style={{ textAlign: "center", color: "#e5e9ec", cursor: "pointer" }}
                    data-toggle="modal"
                    data-target="#shareToEmail"
                  >
                    Email
                  </li>
                </ul>
              </div>

            </div>
            </div>
            </div>
          </div>
          
         
          <div
                  id="PrintUPI"
                  className="printTemplates template2" 
                >
                  <div className="my-5 page pagesizea4" size="A4">
                    <div id="printdiv2">
                      <div
                        className="row px-5 py-4"
                        style={{ backgroundColor: "#268ddd" }}
                      >
                        <div
                          id="ember2512"
                          className="col-md-4 d-flex justify-content-start ribbon text-ellipsis"
                        >
                        </div>
                        <div className="col-md-4 text-center">
                          <center className="h3 text-white">
                            <b>UPI PAYMENTS</b>
                          </center>
                        </div>

                        <div className="col-md-4 text-white text-center" />
                          {startDate != "" && endDate != "" ? (
                          <center className="h6 text-white">
                            {startDate} {"TO"} {endDate}
                          </center>
                        ) : null}
                        
                      </div>

                      
                        <section className="px-5 py-1">
                            <div className="row bb pb-2 mt-3">
                              <div className="col-4 pl-0">
                                <h5
                                  className="text-secondary font-weight-bold"
                                >
                                  {otherDetails.cmpName}
                                </h5>
                                <p className="address font-weight-bold text-dark">
                                  {otherDetails.cmpAddress}<br />
                                  {otherDetails.City}<br />
                                  {otherDetails.State}-{otherDetails.Pincode}<br />
                                  Mob: {otherDetails.Contact}
                                </p>
                              </div>
                              
                              <div className="col-6 d-flex justify-content-end">
                                <div className="bal_section pt-3 pr-3">
                                  <span className="h4 font-weight-bold text-info">TOTAL AMOUNT: <span id="bal">{totalAmount}</span></span>
                                </div>
                              </div>
                            </div>

                          <table className="table table-hover table-bordered template2table mt-2" id="UPIPaymentsTable">
                            <thead style={{ background: "#22b8d1" }}>
                              <tr className="template3tablehead">
                                <th
                                  className="text-center"
                                  style={{ color: "black" }}
                                >
                                  DATE
                                </th>
                                <th
                                  className="text-center"
                                  style={{ color: "black" }}
                                >
                                  TYPE
                                </th>
                                <th
                                  className="text-center"
                                  style={{ color: "black" }}
                                >
                                  NAME
                                </th>
                                <th
                                  className="text-center"
                                  style={{ color: "black" }}
                                >
                                  REF. NO.
                                </th>
                                <th
                                  className="text-center"
                                  style={{ color: "black" }}
                                >
                                  UPI ID
                                </th>
                                <th
                                  className="text-center"
                                  style={{ color: "black" }}
                                >
                                  AMOUNT
                                </th>
                              </tr>
                            </thead>
                            <tbody style={{ backgroundColor: "#999999ab" }}>
                            {upiPayments &&
                             upiPayments.map((payment) => (
                            <>
                              {payment.invoice.map((inv) => (
                                <tr key={inv.id}>
                                  <td style={{ textAlign: "center",color: "black" }}>{inv.invoice_date}</td>
                                  <td style={{ color: "black" }}>INVOICE</td>
                                  <td style={{ color: "black" }}>{inv.Customer.first_name} {inv.Customer.last_name}</td>
                                  <td style={{ color: "black" }}>{inv.reference_no}</td>
                                  <td style={{ color: "black" }}>{inv.upi_no}</td>
                                  <td style={{ color: "black" }}>{inv.paid_off}</td>
                                </tr>
                              ))}
                              {payment.recInvoice.map((recInv) => (
                                <tr key={recInv.id} >
                                  <td style={{ textAlign: "center",color: "black" }}>{recInv.start_date}</td>
                                  <td style={{ color: "black" }}>RECURRING INVOICE</td>
                                  <td style={{ color: "black" }}>{recInv.Customer.first_name} {recInv.Customer.last_name}</td>
                                  <td style={{ color: "black" }}>{recInv.reference_no}</td>
                                  <td style={{ color: "black" }}>{recInv.upi_no}</td>
                                  <td style={{ color: "black" }}>{recInv.paid_off}</td>
                                </tr>
                              ))}
                              {payment.creditNote.map((crdNt) => (
                                <tr key={crdNt.id}>
                                  <td style={{ textAlign: "center",color: "black" }}>{crdNt.credit_note_date}</td>
                                  <td style={{ color: "black" }}>CREDIT NOTE</td>
                                  <td style={{ color: "black" }}>{crdNt.Customer.first_name} {crdNt.Customer.last_name}</td>
                                  <td style={{ color: "black" }}>{crdNt.reference_no}</td>
                                  <td style={{ color: "black" }}>{crdNt.upi_no}</td>
                                  <td style={{ color: "black" }}>{crdNt.paid}</td>
                                </tr>
                              ))}
                              {payment.salesOrder.map((sordr) => (
                                <tr key={sordr.id}>
                                  <td style={{ textAlign: "center",color: "black" }}>{sordr.sales_order_date}</td>
                                  <td style={{ color: "black" }}>SALES ORDER</td>
                                  <td style={{ color: "black" }}>{sordr.Customer.first_name} {sordr.Customer.last_name}</td>
                                  <td style={{ color: "black" }}>{sordr.reference_no}</td>
                                  <td style={{ color: "black" }}>{sordr.upi_no}</td>
                                  <td style={{ color: "black" }}>{sordr.paid_off}</td>
                                </tr>
                              ))}
                              {payment.retainerInvoice.map((rtInv) => (
                                <tr key={rtInv.id}>
                                  <td style={{ textAlign: "center",color: "black" }}>{rtInv.ret_invoice_date}</td>
                                  <td style={{ color: "black" }}>RETAINER INVOICE</td>
                                  <td style={{ color: "black" }}>{rtInv.Customer.first_name} {rtInv.Customer.last_name}</td>
                                  <td style={{ color: "black" }}>{rtInv.reference_no}</td>
                                  <td style={{ color: "black" }}>{rtInv.upi_no}</td>
                                  <td style={{ color: "black" }}>{rtInv.paid_off}</td>
                                </tr>
                              ))}
                              {payment.bill.map((bill) => (
                                <tr key={bill.id}>
                                  <td style={{ textAlign: "center",color: "black" }}>{bill.bill_date}</td>
                                  <td style={{ color: "black" }}>BILL</td>
                                  <td style={{ color: "black" }}>{bill.Vendor.First_name} {bill.Vendor.Last_name}</td>
                                  <td style={{ color: "black" }}>{bill.reference_no}</td>
                                  <td style={{ color: "black" }}>{bill.upi_no}</td>
                                  <td style={{ color: "black" }}>{bill.paid_off}</td>
                                </tr>
                              ))}
                              {payment.recurringBill.map((rcrbl) => (
                                <tr key={rcrbl.id}>
                                  <td style={{ textAlign: "center",color: "black" }}>{rcrbl.date}</td>
                                  <td style={{ color: "black" }}>RECURRING BILL</td>
                                  <td style={{ color: "black" }}>{rcrbl.Vendor.First_name} {rcrbl.Vendor.Last_name}</td>
                                  <td style={{ color: "black" }}>{rcrbl.reference_no}</td>
                                  <td style={{ color: "black" }}>{rcrbl.upi_no}</td>
                                  <td style={{ color: "black" }}>{rcrbl.paid}</td>
                                </tr>
                              ))}
                              {payment.purchaseOrder.map((pordr) => (
                                <tr key={pordr.id}>
                                  <td style={{ textAlign: "center",color: "black" }}>{pordr.purchase_order_date}</td>
                                  <td style={{ color: "black" }}>PURCHASE ORDER</td>
                                  <td style={{ color: "black" }}>{pordr.Vendor.First_name} {pordr.Vendor.Last_name}</td>
                                  <td style={{ color: "black" }}>{pordr.reference_no}</td>
                                  <td style={{ color: "black" }}>{pordr.upi_no}</td>
                                  <td style={{ color: "black" }}>{pordr.paid_off}</td>
                                </tr>
                              ))}
                              {payment.debitNote.map((dbtnt) => (
                                <tr key={dbtnt.id}>
                                  <td style={{ textAlign: "center",color: "black" }}>{dbtnt.debit_note_date}</td>
                                  <td style={{ color: "black" }}>DEBIT NOTE</td>
                                  <td style={{ color: "black" }}>{dbtnt.Vendor.First_name} {dbtnt.Vendor.Last_name}</td>
                                  <td style={{ color: "black" }}>{dbtnt.reference_no}</td>
                                  <td style={{ color: "black" }}>{dbtnt.upi_no}</td>
                                  <td style={{ color: "black" }}>{dbtnt.paid}</td>
                                </tr>
                              ))}
                              {payment.expense.map((exp) => (
                                <tr key={exp.id}>
                                  <td style={{ textAlign: "center",color: "black" }}>{exp.expense_date}</td>
                                  <td style={{ color: "black" }}>EXPENSE</td>
                                  <td style={{ color: "black" }}>{exp.Vendor.First_name} {exp.Vendor.Last_name}</td>
                                  <td style={{ color: "black" }}>{exp.reference_no}</td>
                                  <td style={{ color: "black" }}>{exp.upi_no}</td>
                                  <td style={{ color: "black" }}>{exp.amount}</td>
                                </tr>
                              ))}
                              {payment.empLoan.map((empLoan) => (
                              <tr key={empLoan.id}>
                                <td style={{ textAlign: "center",color: "black" }}>{empLoan.loan_date}</td>
                                <td style={{ color: "black" }}>EMPLOYEE LOAN</td>
                                <td style={{ color: "black" }}>{empLoan.employee.first_name} {empLoan.employee.last_name}</td>
                                <td style={{ color: "black" }}></td>
                                <td style={{ color: "black" }}>{empLoan.upi}</td>
                                <td style={{ color: "black" }}>{empLoan.loan_amount}</td>
                              </tr>
                            ))}
                            {payment.empAddLoan.map((empAddLoan) => (
                              <tr key={empAddLoan.id}>
                                <td style={{ textAlign: "center",color: "black" }}>{empAddLoan.new_date}</td>
                                <td style={{ color: "black" }}>EMPLOYEE ADDITIONAL LOAN</td>
                                <td style={{ color: "black" }}>{empAddLoan.employee.first_name} {empAddLoan.employee.last_name}</td>
                                <td style={{ color: "black" }}></td>
                                <td style={{ color: "black" }}>{empAddLoan.upi}</td>
                                <td style={{ color: "black" }}>{empAddLoan.new_loan}</td>
                              </tr>
                            ))}
                            {payment.loanRepay.map((lnRpy) => (
                              <tr key={lnRpy.id}>
                                <td style={{ textAlign: "center",color: "black" }}>{lnRpy.payment_date}</td>
                                <td style={{ color: "black" }}>EMI PAID</td>
                                <td style={{ color: "black" }}>{lnRpy.employee.first_name} {lnRpy.employee.last_name}</td>
                                <td style={{ color: "black" }}></td>
                                <td style={{ color: "black" }}>{lnRpy.upi}</td>
                                <td style={{ color: "black" }}>{lnRpy.principle_amount}</td>
                              </tr>
                            ))}
                            {payment.loanAccount.map((loanAcc) => (
                            <tr key={loanAcc.id}>
                              <td style={{ textAlign: "center" }}>{loanAcc.loan_date}</td>
                              <td>LOAN</td>
                              <td>{loanAcc.loan.account_name}</td>
                              <td></td>
                              <td>{loanAcc.recieved_upi}</td>
                              <td>{loanAcc.loan_amount}</td>
                            </tr>
                          ))}
                          {payment.loanAdditional.map((lonAddAcc) => (
                            <tr key={lonAddAcc.id}>
                              <td style={{ textAlign: "center" }}>{lonAddAcc.loan_date}</td>
                              <td>ADDITIONAL LOAN</td>
                              <td>{lonAddAcc.loan.account_name}</td>
                              <td></td>
                              <td>{lonAddAcc.recieved_upi}</td>
                              <td>{lonAddAcc.loan_amount}</td>
                            </tr>
                          ))}
                          {payment.emiPaid.map((lonAccEmi) => (
                            <tr key={lonAccEmi.id}>
                              <td style={{ textAlign: "center" }}>{lonAccEmi.loan_date}</td>
                              <td>EMI PAID</td>
                              <td>{lonAccEmi.loan.account_name}</td>
                              <td></td>
                              <td>{lonAccEmi.recieved_upi}</td>
                              <td>{lonAccEmi.loan_amount}</td>
                            </tr>
                          ))}
                            </>
                          ))}
                              
                        </tbody>
                      </table>
                    </section>
                  </div>
                </div>
              </div>
            </div>

      </div>
      {/* <!-- Share To Email Modal --> */}
      <div className="modal fade" id="shareToEmail">
        <div className="modal-dialog modal-lg">
          <div className="modal-content" style={{ backgroundColor: "#213b52" }}>
            <div className="modal-header">
              <h5 className="m-3">Share Statement </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form
                onSubmit={handleShareEmail}
                className="needs-validation px-1"
                id="share_to_email_form"
              >
                <div className="card p-3 w-100">
                  <div className="form-group">
                    <label for="emailIds">Email IDs</label>
                    <textarea
                      className="form-control"
                      name="email_ids"
                      id="emailIds"
                      rows="3"
                      placeholder="Multiple emails can be added by separating with a comma(,)."
                      value={emailIds}
                      onChange={(e) => setEmailIds(e.target.value)}
                      required
                    />
                  </div>
                  <div className="form-group mt-2">
                    <label for="item_unitname">Message(optional)</label>
                    <textarea
                      name="email_message"
                      id="email_message"
                      className="form-control"
                      cols=""
                      rows="4"
                      value={emailMessage}
                      onChange={(e) => setEmailMessage(e.target.value)}
                      placeholder="This message will be sent along with Bill details."
                    />
                  </div>
                </div>
                <div
                  className="modal-footer d-flex justify-content-center w-100"
                  style={{ borderTop: "1px solid #ffffff" }}
                >
                  <button
                    type="submit"
                    id="share_with_email"
                    className="submitShareEmailBtn w-50 text-uppercase"
                  >
                    SEND MAIL
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UPIStatement;
